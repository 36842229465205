import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp, useGridApiRef } from '@mui/x-data-grid';
import * as BSIcon from "react-icons/bs"
import UserGroup from '../../domain/UserGroup';
import SampleList from '../../domain/SampleList';
import User from '../../domain/User';
import { calculateDataGridPage } from '../utils/gapCommonTsx';


interface UserTableData {
  searchResults: GridRowsProp;
  checkboxSelection: boolean;
  ToggledMenu?: boolean;
  SelectedRow?: Array<string> ;
  OnSelect?(selectRow: Array<string>) : void;
  onEditUser?(row: User): void;
}

export default function UserTable_MUI(props: UserTableData) {
  const apiRef = useGridApiRef();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [selectedRows, setSelectedRows] = 
    React.useState<GridRowsProp>([]);

  const UserColumns: GridColDef[] = [
    { field: 'email', headerName: 'User Email', sortable: true, flex:1, width: 250 },
    { field: 'firstname', headerName: 'First Name', sortable: true, width: 150 },
    { field: 'lastname', headerName: 'Last Name', sortable: true, width: 150 },
    { field: 'organization', headerName: 'Organization', sortable: true, width: 250 },
    { field: 'groupNamelist', headerName: 'User Groups', sortable: false, flex:1 },
    {
      field: 'actions', type:'actions', headerName: 'Actions', sortable: false, width: 100,
      renderCell: (params) => {
        return (<div>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => { if (props.onEditUser) props.onEditUser(params.row) }}>
            <BSIcon.BsPencil className="margin-bottom-3" title="Assign User Group" />
          </button>
        </div>
        )
      }
    }
  ];

  const getTogglableColumns = () => {
    if(props.ToggledMenu){
      return UserColumns
        .filter((column) => column.field !== "groupNamelist" && column.field !== "actions");
    }
    else{
      return UserColumns;
    }
  };

  useEffect(() => {
    if (props.searchResults.length > 0) {
      const s = props.searchResults.filter((r) => props.SelectedRow?.includes(r.userid)).map((r) => r.userid);
      setRowSelectionModel(s);
      if (apiRef.current?.getSortedRowIds() != null) {
        var selectedpage = 0;
        if (props.SelectedRow != undefined && props.SelectedRow?.length > 0) {
          selectedpage = calculateDataGridPage(apiRef.current?.getSortedRowIds(), props.SelectedRow[0], paginationModel.pageSize);
        }
        apiRef.current.setPage(selectedpage);
      }
    };

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, [props.SelectedRow]);

  return (
    <div>
      <div>
        <DataGrid
          checkboxSelection = {props.checkboxSelection}
          rows={props.searchResults}
          columns={getTogglableColumns()}
          getRowId={(row) => row.userid}
          apiRef={apiRef}

          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}

          slotProps={{
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}

          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}

          onRowSelectionModelChange={(e) => {
            setRowSelectionModel(e);
            const selectedIDs = new Set(e);
            const selectedRows = props.searchResults.filter((r) => selectedIDs.has(r.userid));
            setSelectedRows(selectedRows);
            if(props.OnSelect != null) {
              props.OnSelect(selectedRows.map(({ userid }) => userid));
            }
          }}
          rowSelectionModel={rowSelectionModel}

          sx={{ ".MuiDataGrid-cell": { p: 1 } }}
        />
      </div>
    </div>
  );
};