import React, { ChangeEvent } from 'react';
import "./reference.css";
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import NCBITrackData from '../../../../domain/NCBITrackData';
import * as BSIcon from "react-icons/bs";

interface TrackDataTableData {
  trackdatas: GridRowsProp;
  selectedrowid: string;
  onDeletetrackData(row: NCBITrackData) : void;
  onEditTrackData(row: NCBITrackData) : void;
  onUpdateRowData(row: NCBITrackData) : void;
  onViewTrackData(row: NCBITrackData) : void;
}

export default function TrackDataTable_MUI (props : TrackDataTableData) {

  const TrackColumns : GridColDef[] = [
    { field: 'tracktype', headerName: 'Track Type', sortable: true, width:130}, 
    { field: 'trackname', headerName: 'Track Name', sortable: true, width:200}, 
    { field: 'trackdataurl', headerName: 'Track Data Location', flex: 1, width:400}, 
    { field: 'description', headerName: 'Description', sortable: false, width:400}, 
    { field: 'ispublic', headerName: 'Publish', sortable: false, width:80, 
      renderCell: (params) => {
        return (
          <div className="checkbox">
            <label>
              <input type="checkbox" checked={ params.row.ispublic } onChange={event => onPublicChange(event, params.row)} />
            </label>
          </div>
        )
      }
    }, 
    { field: 'actions', type:'actions', headerName: 'Actions', sortable: false, width:130, 
      renderCell: (params) => {
          return <div className="tablecellcenter">
            <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => props.onEditTrackData(params.row)}>
              <BSIcon.BsPencil className="margin-bottom-3" title="Edit Track Data" />
            </button>
            <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => props.onViewTrackData(params.row)}>
              <BSIcon.BsViewStacked className="margin-bottom-3" title="View Track" />
            </button>
            <button type="button" className="btn btn-danger btn-sm" onClick={() => onDeleteTrackData(params.row)}>
              <BSIcon.BsTrashFill className="margin-bottom-3" title="Delete Track Data" />
            </button>
          </div>;
        }
    }
  ];

  const onDeleteTrackData =(row: NCBITrackData)=>{
    var r = window.confirm("Do you want to delete Track Data: " + row.trackname);
		if (r === false) {
		    return;
		}

    props.onDeletetrackData(row);
  }

  const onPublicChange =(event: ChangeEvent<HTMLInputElement>, row: NCBITrackData) => {
    var target = event.target;
    var new_value = target.checked;
    var newRow = JSON.parse(JSON.stringify(row));
    newRow.ispublic = new_value;
    props.onUpdateRowData(newRow);
  }

    return (
      <div>
        <DataGrid
        rows={props.trackdatas}
        columns={TrackColumns}
        getRowId={(row) => row.referenceId}

        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}

        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}

        slotProps={{
          pagination: {
            showFirstButton: true,
            showLastButton: true,
          },
        }}

        pageSizeOptions={[10, 25, 50, 100]}
        sx={{ ".MuiDataGrid-cell": { p: 1 } }}
      />
      </div>
    );
};