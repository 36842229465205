import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp, useGridApiRef } from '@mui/x-data-grid';
import * as BSIcon from "react-icons/bs"
import UserGroup from '../../domain/UserGroup';
import { calculateDataGridPage } from '../utils/gapCommonTsx';


interface GroupTableData {
  searchResults: GridRowsProp;
  checkboxSelection: boolean;
  ToggledMenu: boolean;
  SelectedRow?: Array<string> ;
  OnSelect?(selectedRows: Array<string>): void;
  onEditGroup?(row: UserGroup): void;
  onDeleteGroup?(row: UserGroup): void;
}

export default function GroupTable_MUI(props: GroupTableData) {
  const apiRef = useGridApiRef();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [selectedRows, setSelectedRows] = 
    React.useState<GridRowsProp>([]);

  const GroupColumns: GridColDef[] = [
    { field: 'groupname', headerName: 'Group Name', sortable: true, width: 300,},
    { field: 'description', headerName: 'Description', sortable: false, flex: 1,  width: 300 },
    { field: 'userNamelist', headerName: 'Users in Group', sortable: false,flex: 1, width:300 },
    {
      field: 'actions', headerName: 'Actions', sortable: false, width: 100,
      renderCell: (params) => {
        return (
          <div>
            <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => { if (props.onEditGroup) props.onEditGroup(params.row) }}>
              <BSIcon.BsPencil className="margin-bottom-3" title="Edit Group" />
            </button>
            {(params.row.userNamelist === undefined || params.row.userNamelist === "") &&
              <button type="button" className="btn btn-danger btn-sm" onClick={() => onDeleteGroup(params.row)}>
                <BSIcon.BsTrashFill className="margin-bottom-3" title="Delete Group" />
              </button>
            }
          </div>
        )
      }
    }
  ];


const getTogglableColumns = () => {
  if(props.ToggledMenu){
    return GroupColumns
      .filter((column) => column.field !== "userNamelist" && column.field !== "actions");
  }
  else{
    return GroupColumns;
  }
};

  const onDeleteGroup = (row: UserGroup) => {
    var r = window.confirm("You will delete Group " + row.groupname + ",Please confirm it.");
    if (r === false) {
      return;
    }
    if (props.onDeleteGroup) props.onDeleteGroup(row);
  }

  useEffect(() => {
    if (props.searchResults.length > 0) {
      const s = props.searchResults.filter((r) => props.SelectedRow?.includes(r.groupid)).map((r) => r.groupid);
      setRowSelectionModel(s);
      if (apiRef.current?.getSortedRowIds() != null) {
        var selectedpage = 0;
        if (props.SelectedRow != undefined && props.SelectedRow?.length > 0) {
          selectedpage = calculateDataGridPage(apiRef.current?.getSortedRowIds(), props.SelectedRow[0], paginationModel.pageSize);
        }
        apiRef.current.setPage(selectedpage);
      }
    };

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, [props.SelectedRow]);

  return (
    <div>
      <DataGrid
        checkboxSelection={props.checkboxSelection}
        rows={props.searchResults}
        columns={getTogglableColumns()}
        getRowId={(row) => row.groupid}
        apiRef={apiRef}

        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 300}

        slotProps={{
          pagination: {
            showFirstButton: true,
            showLastButton: true,
          },
        }}

        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        pageSizeOptions={[10, 25, 50, 100]}

        onRowSelectionModelChange={(e) => {
          setRowSelectionModel(e);
          const selectedIDs = new Set(e);
          const selectedRows = props.searchResults.filter((r) => selectedIDs.has(r.groupid));
          setSelectedRows(selectedRows);
          if(props.OnSelect != null) {
            props.OnSelect( selectedRows.map(({ groupid }) => groupid));
          }
        }}
        rowSelectionModel={rowSelectionModel}

        sx={{ ".MuiDataGrid-cell": { p: 1 } }}
      />
    </div>
  );
};