import React from 'react';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams, GridRowSelectionModel, GridRowsProp, useGridApiRef } from '@mui/x-data-grid';
import SampleList from '../../../domain/SampleList';
import parse from 'html-react-parser';
import * as MUIIcon from '@mui/icons-material/';

import * as BSIcon from "react-icons/bs"

interface SampleListTableData {
  searchResults: GridRowsProp;
  checkboxSelection: boolean;
  preselectedrowid: string;
  nonSelected: Array<string>;
  OnRowSelect(RowIds: Array<string>) : void;
  onDownloadList(row: SampleList) : void;
  onDeleteList(row: SampleList) : void;
  onEditList(row: SampleList) : void;
}

export default function SampleListTable_MUI (props : SampleListTableData) {
  const apiRef = useGridApiRef();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [selectedRows, setSelectedRows] = 
    React.useState<GridRowsProp>([]);
    
  const ListColumns : GridColDef[] = [
    { field: 'listname', headerName: 'List Name', sortable: true, width: 200},
    { field: 'listtype', headerName: 'List Type', sortable: true, width: 100},
    { field: 'username', headerName: 'Owner', sortable: true, width: 100},
    { field: 'listsource', headerName: 'List Source', sortable: true, width: 80},
    { field: 'totallistnumber', headerName: 'List Number', sortable: false, width: 80},
    { field: 'createdate', headerName: 'Create Date', sortable: true, },
    { field: 'vcf', headerName: 'VCF', sortable: false, flex:1,
      renderCell: (params) => {
        if(params.row.vcf !== undefined){
          var vcfs = params.row.vcf;
          vcfs = vcfs.replaceAll(" ", "<br>");
          return <div>{ parse(vcfs) }</div>
        }
        else return <div>{params.row.vcf} </div>
      }
    },
    { field: 'groupnames', headerName: 'User Group', sortable: false, width: 100},
    { field: 'depot_cohorts', headerName: 'Cohorts', sortable: false, width: 100},
    { field: 'actions', 
      type:'actions', 
      width: 130,
      renderCell: (params) => {
        return <div>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => props.onDownloadList(params.row)}>
            <BSIcon.BsDownload className="margin-bottom-3" title="Download List" />
          </button>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => props.onEditList(params.row)}>
            <BSIcon.BsPencil className="margin-bottom-3" title="Edit List" />
          </button>
          <button type="button" className="btn btn-danger btn-sm" onClick={() => onDeleteList(params.row)}>
            <BSIcon.BsTrashFill className="margin-bottom-3" title="Delete List" />
          </button>

        </div>;
      }
    }
  ];

  const onDeleteList =(row: SampleList)=>{
    var r = window.confirm("Do you want to delete list " + row.listname);
		if (r === false) {
		    return;
		}

    props.onDeleteList(row);
  }

    return (
      <div>
        <DataGrid
          checkboxSelection={props.checkboxSelection}
          rows={props.searchResults}
          columns={ListColumns}
          getRowId={(row) => row.listid}

          isRowSelectable={(params: GridRowParams) => !props.nonSelected.includes(params.row.listid)}

          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}

          slotProps={{
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}

          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25, 50, 100]}

          onRowSelectionModelChange={(e) => {
            setRowSelectionModel(e);
            const selectedIDs = new Set(e);
            const selectedRows = props.searchResults.filter((r) => selectedIDs.has(r.listid));
            setSelectedRows(selectedRows);
            if(props.OnRowSelect != null) {
              props.OnRowSelect( selectedRows.map(({ listid }) => listid));
            }
          }}
          rowSelectionModel={rowSelectionModel}

          sx={{ ".MuiDataGrid-cell": { p: 1 } }}
        />
      </div>
    );
};