import queryString from 'query-string';
import { GridRowId } from '@mui/x-data-grid';

export const dataSort = function(a,b,asc){
    var result;

    if (typeof asc == "undefined") asc = true;

    if (a === null) return 1;
    if (b === null) return -1;
    if (a === null && b === null) return 0;

    result = a - b;

    if (isNaN(result)) {
        return (asc) ? a.toString().localeCompare(b) : b.toString().localeCompare(a);
    }
    else {
        return (asc) ? result : -result;
    }
}


export const findAndRemove = function (array, property, value) {
    array.forEach(function(result, index) {
        if(result[property] === value) {
          array.splice(index, 1);
        }    
      });
};

export const removeOrcidCode =(statename, transition) =>{
    if(sessionStorage.userSession !== undefined){
        var usersession = JSON.parse(sessionStorage.userSession);
        const queries = queryString.parse(window.location.search);
        if (usersession.isverified && Object.keys(queries).length > 0) {
          var stateService = transition.router.stateService
          stateService.go(statename, { orcidcode: '' });
        }
    }
}

export const  updateTableRow = (array, target_index, form) =>{
    return array.map((item, index) => {
      if (index === target_index) {
        // This is the item we care about - lets replace it with updated data
        return form;
      }
  
      // Otherwise, this is other rows we do not want - keep it as-is
      return item;
    })
}

export const calculateTablePage = (node, currentrowid, keyField) => {    //is not index id, it is the table keyField
  var selectedpage = 1;
  var selectedrowid = [];

  if(node === undefined) return[selectedpage, selectedrowid];

  var datasource = node.paginationContext;
  var { currSizePerPage, currPage } = datasource;
  var sortedData = datasource.props.data;

  if(currentrowid === "") {
    return [currPage, selectedrowid];
  }

  selectedrowid = [currentrowid];
  
  var pos = 0;
 
  for (var i = 0; i < sortedData.length; i++) {
    if (sortedData[i][keyField] === currentrowid) {
      selectedpage = parseInt(pos / currSizePerPage) + 1;
      break;
    } else {
      pos++;
    }
  }

  return [selectedpage, selectedrowid];
};

//Tsx Format: export const  calculateTablePage = (datasource : GridRowId[], currentrowid:string) => {
export const  calculateMUIDataGridPage = (datasource, currentrowid, pagesize) => {
  var selectedpage = 0;
  if(datasource === undefined || currentrowid === "") return selectedpage;

  var pos = 0;
  for (var i = 0; i < datasource.length; i++) {
    if (datasource[i] === currentrowid) {
      selectedpage = parseInt((pos/pagesize).toString());
      break;
    } else {
      pos++;
    }
  }
  return selectedpage;
}

