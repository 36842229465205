import React, { ComponentType } from "react";
import parse from 'html-react-parser';
import Modal from 'react-bootstrap/Modal'
import {  UISref, Transition, UISrefActive, UIRouterGlobals } from "@uirouter/react";
import UserSession from '../../services/UserSession'
import axios from "axios";
//import validator from 'validator'
import BlockUi from '@availity/block-ui';
import { DNA } from  'react-loader-spinner';
import '@availity/block-ui/src/BlockUi.css';
import queryString from 'query-string';
import niaidlogowhite from '../../assets/images/niaid-logo-white.svg';
import './Header.css'
import API_URL from '../../services/Enviroment';
import SignIn from './SignIn';

type HeaderState = {
    showlogin: boolean,
	targetstate: string,
	blocking: boolean,
	loginmessage: string,
	errors: string,
}

interface FuncPros {
	updateState(login:boolean): void,
}

export default class Header extends React.Component <FuncPros, HeaderState> {
	usersession = UserSession.getUserSession();
	constructor(props: FuncPros) {
		super(props);
		
		this.state = {
			showlogin: false,
			targetstate: "",
			loginmessage: "",
			blocking: false,
			errors: "",
		};
	}

	handleLoginClose =(islogin:boolean) => {	
		this.setState({showlogin: false})	
		if(islogin && this.state.targetstate !== ""){
      		window.location.href = location.origin + "/" + this.state.targetstate;
     	}
	}

	handleLoginClick =(targetstate: string) =>{
		this.setState({
			showlogin: true, 
			targetstate: targetstate, 
			loginmessage: "Please login to use Resistance feature.",
			blocking: false});
	}

	signOut =() =>{
		this.setState({ blocking: true, errors:"" });
		let url = API_URL +'admin/signOut';
        axios.post(url)
			.then(response => {
				this.usersession = response.data;
				sessionStorage.setItem("userSession", JSON.stringify(response.data));
				this.props.updateState(false);
		})
		.catch(error => {
			console.error('There was an error!' + error.message);
			this.setState({errors:  error.message});
		});
	}

	processOrcideResult =(resultStr : string) => {
		var result = JSON.parse(resultStr);
		var errors = "";
	 	var stateUpdate = {blocking:false} as HeaderState; 

		if(result.error != undefined){
			var error=result.error; 
			if(error && error.length > 0){
				errors = result.errorDescription;
				stateUpdate.errors = errors; 
				this.setState(stateUpdate);
			}
		}
		else {
			if (result.isverified) {
				this.usersession = result;
				sessionStorage.setItem("userSession", JSON.stringify(result));
				this.setState({ showlogin: false, blocking: false });
				this.props.updateState(true);
			}
			else if(result.tbPortalsApiError != undefined){
				var tbportalError = result.tbPortalsApiError;
		 		var orcidid = result.orcid;
				if (tbportalError || tbportalError.length > 0) {
					// if (tbportalError.startsWith("No user exists") && orcidid && orcidid.length > 0) {   //open register
					// 	stateUpdate.registeruser = true;
					// 	stateUpdate.registeremaildisabled = false;
					// 	stateUpdate.orcidlogin = true;
					// 	stateUpdate.registerorcid = orcidid;
					// 	stateUpdate.errors = tbportalError + "(" + orcidid + ")";
					// 	this.setState(stateUpdate);
					// }
				}
			}
			else{
				this.setState({blocking:false, errors: "Please click 'Login with ORCiD'. <br> If it still doesn't work, Please contact GAP support."});
			}
		}
	}

	componentDidMount =() =>{
		const queries = queryString.parse(location.search);
		var orcid_access="";
		var orcid_code ="";
		var errors = "";
		var showlogin = false;
		
		if(queries.orcidaccessdeny != undefined){
			showlogin = true;
			orcid_access = "deny";
			errors = "access denied by orcid.";
			// this.setState({
			// 	errors: errors,
			// 	orcid_access: "",
			// 	orcid_code: orcid_code,
			// 	show: true});
		}
		else if(queries.orcidcode != undefined){
			showlogin = true;
			orcid_code = queries.orcidcode.toString();
			this.setState({showlogin: true, blocking:true});
			if(orcid_code && orcid_code.length >0){
				let url = API_URL +'admin/validateorciduser';
        		axios.post(url,  {"code": orcid_code })
					.then(response => {
						var data = response.data;
						this.processOrcideResult(JSON.stringify(data));
					})
					.catch(error => {
						console.error('There was an error!' + error.message);
						this.setState({ blocking: false, errors:  error.message});
					});
				}
		}
	}

	render(){
		this.usersession = JSON.parse(sessionStorage.userSession);
		return (
			<div className="Header-background">
				{/* <SignIn_function show={this.state.showlogin} targetstate={this.state.targetstate} closeHandle ={() => this.handleLoginClose()} /> */}
				<SignIn 
					blocking={this.state.blocking} 
					show={this.state.showlogin} 
					targetstate={this.state.targetstate} 
					loginmessage={this.state.loginmessage}
					closeHandle ={(islogin:boolean) => this.handleLoginClose(islogin)} />
				<div className="card d-none d-lg-block rounded-0 border-0 top-bar">
					<div className="card-body py-2 px-3">
						<ul className="list-inline mb-0">
							<li className="list-inline-item">
								<img src={require('../../assets/images/favicon-57.png')} alt="U.S. flag" width="20" />
							</li>
							<li className="list-inline-item me-4">
								<a className="small top-bar-text text-decoration-none" href="http://www.hhs.gov/" target="_blank">U.S. Department of Health &amp; Human Services</a>
							</li>
							<li className="list-inline-item me-4">
								<a className="small top-bar-text text-decoration-none" href="https://www.nih.gov/" target="_blank">National Institutes of Health</a>
							</li>
							<li className="list-inline-item me-4">
								<a className="small top-bar-text text-decoration-none" href="https://www.niaid.nih.gov/" target="_blank">National Institute of Allergy &amp; Infectious Diseases</a>
							</li>
							<li className="list-inline-item me-4">
								<a className="small top-bar-text text-decoration-none" href="https://bioinformatics.niaid.nih.gov/" target="_blank">bioinformatics @NIAID</a>
							</li>
						</ul>
					</div>
				</div>
				{/* <nav className="navbar navbar-expand-xxl  text-white"> */}
				<nav className="navbar navbar-expand-xxl text-white navbar-dark " aria-label="GAP menubar">
					<div className="container">
						<UISref to="home">
							<a className="navbar-brand">
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0 border-end border-light pe-2 pe-md-3">
										<img src={niaidlogowhite} className="logo" alt="logo" />
									</div>
									<div className="flex-grow-1 ms-2 ms-md-3">
										<p className="mb-0 display-6 fw-bold logotype text-white">
											TB Portals &nbsp;
											<img src={require('../../assets/images/gap-logo.png')} className="app-logo" alt="logo" />
										</p>
									</div>
								</div>
							</a>
						</UISref>
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						{/* <!-- Large-menu --> */}
						<div className="collapse navbar-collapse ms-xxl-5 p-5 p-xxl-0" id="navbarSupportedContent">
							<ul className="navbar-nav me-auto mb-2 mb-sm-0">
								<li className="nav-item dropdown">
									<div style={{ display: "flex" }} >
										<UISref to="variants">
											<a className="nav-link text-white dropdown_menu_location" id="navbarDropdown" role="button">
												Variants
											</a>
										</UISref>
										<div>
											<a className="nav-link text-white dropdown-toggle dropdown_icon_location" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											</a>
											<ul className="dropdown-menu mt-3 rounded-0 rounded-bottom" aria-labelledby="navbarDropdown">
												<li><UISref className="dropdown-item" to="GWAS"><a>GWAS</a></UISref></li>
												<li><UISref className="dropdown-item" to="Clustering"><a>Clustering</a></UISref></li>
												<li><UISref className="dropdown-item" to="VariantSearch"><a>Variant Search</a></UISref> </li>
												<li><UISref className="dropdown-item" to="Distance"><a>Distance</a></UISref> </li>
												<li><UISref className="dropdown-item" to="Visualization"><a>Cohort Visualization</a></UISref> </li>
											</ul>
										</div>
									</div>
								</li>
								<span className="mx-2"></span>
								<li className="nav-item dropdown">
									<div style={{ display: "flex" }} >
										<UISref to="resistance">
											<a className="nav-link text-white dropdown_menu_location" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">
												Resistance
											</a>
										</UISref>
										<div>
											<a className="nav-link text-white dropdown-toggle dropdown_icon_location" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											</a>
											<ul className="dropdown-menu mt-3 rounded-0 rounded-bottom" aria-labelledby="navbarDropdown">
											{this.usersession.isverified &&
												<div>
													<li><UISref className="dropdown-item" to="tbresistancePrediction"><a>TB Resistance Prediction (upload fastq)</a></UISref></li>
													<li><UISref className="dropdown-item" to="ntmresistancePrediction"><a>NTM Resistance Prediction (upload fastq)</a></UISref></li>
												</div>
											}
											{!this.usersession.isverified &&
												<div>
													<li><a className="dropdown-item" onClick={()=>this.handleLoginClick('tbresistancePrediction')}>TB Resistance Prediction (upload fastq)</a></li>
													<li><a className="dropdown-item" onClick={()=>this.handleLoginClick('ntmresistancePrediction')}>NTM Resistance Prediction (upload fastq)</a></li>
												</div>
											}
											<li><UISref className="dropdown-item" to="tbportalsdata"><a>TB Portals data</a></UISref> </li>
											</ul>
										</div>
									</div>
								</li>
								<span className="mx-2"></span>
								<li>
									<UISrefActive>
										<UISref to="genomeBrowser" >
											<a className="nav-link text-white" id="navbarDropdown" role="button" aria-expanded="false">Genome</a>
										</UISref>
									</UISrefActive>
								</li>

								<span className="mx-2"></span>
								<li className="nav-item dropdown">
									<a className="nav-link text-white dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										TB Portals Sites
									</a>
									<ul className="dropdown-menu mt-3 rounded-0 rounded-bottom" aria-labelledby="navbarDropdown">
										<li><a className="dropdown-item" href="https://data.tbportals.niaid.nih.gov/" target="_blank">Case Browser</a></li>
										<li><a className="dropdown-item" href="https://depot.tbportals.niaid.nih.gov/" target="_blank">DEPOT</a></li>
										<li><a className="dropdown-item" href="https://rap.tbportals.niaid.nih.gov" target="_blank">RAP</a></li>
										<li><a className="dropdown-item" href="https://tbportals.niaid.nih.gov/" target="_blank">TB Portals Home</a></li>
									</ul>
								</li>
								<span className="mx-2"></span>
								{this.usersession.isverified &&
									<div>
										<li className="nav-item dropdown">
											<div style={{ display: "flex" }} >
												<a className="nav-link  text-white dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
													User Profile
												</a>
												<ul className="dropdown-menu dropdown-submenu mt-3 rounded-0 rounded-bottom" aria-labelledby="navbarDropdown">
													{this.usersession.isadminuser &&
														<li>
															<a className="dropdown-item">
																<span >Admin</span>
																<span className="headcaret"></span>
															</a>
															<ul className="dropdown-menu dropdown-submenu">
																<li><UISref className="dropdown-item" to="adminvcf"><a>VCF Management</a></UISref></li>
																<li><UISref className="dropdown-item" to="userAdmin"><a>User Management</a></UISref></li>
																<li><UISref className="dropdown-item" to="loadtbprofileresults"><a>Load TBProfile Result Files</a></UISref></li>
																<li><UISref className="dropdown-item" to="mgmreference"><a>Reference Management</a></UISref></li>
															</ul>
														</li>
													}
													<li><UISref className="dropdown-item" to="uservcf"><a>User VCF Management</a></UISref></li>
													<li><UISref className="dropdown-item" to="listmanagement"><a>List Management</a></UISref></li>
													<li><UISref className="dropdown-item" to="usermetadata"><a>Sample Metadata</a></UISref></li>
												</ul>
												<span className="mx-2"></span>
											</div>
										</li>
									</div>
								}

								<li className="nav-item dropdown">
									<a className="nav-link text-white dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
										Help
									</a>
									<ul className="dropdown-menu mt-3 rounded-0 rounded-bottom" aria-labelledby="navbarDropdown">
										<li><UISref className="dropdown-item" to="about"><a>About</a></UISref> </li>
										<li><UISref className="dropdown-item" to="releasenotes"><a>Release Notes</a></UISref> </li>
										<li><UISref className="dropdown-item" to="faq"><a>FAQ</a></UISref> </li>
										<li><UISref className="dropdown-item" to="userguide"><a>User Guide</a></UISref> </li>

									</ul>
								</li>
								<span className="mx-2"></span>
							</ul>
							{!this.usersession.isverified &&
								<div>
									<div className=" d-xxl-block">
										<a className="btn btn-outline-light" onClick={()=>this.handleLoginClick('')}>
											Sign in
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right"
												viewBox="0 0 16 16">
												<path fillRule="evenodd"
													d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
											</svg>
										</a>
									</div>
								</div>
							}
							{this.usersession.isverified &&
								<div className="d-xxl-block">
									<div className="Header-float-right">
										<div className="margin-top-8">
											Login as: {this.usersession.email} &nbsp;&nbsp;&nbsp;
										</div>
										<div>
										<a onClick={this.signOut} className="btn btn-outline-light">Sign Out</a>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</nav>
			</div>
		);
	};
};