import React, { useEffect } from 'react';

import $ from 'jquery';
import './VariantSearch.css';
import { DataGrid, GridColDef, GridRowsProp, useGridApiRef } from '@mui/x-data-grid';
import Variant from '../../../domain/Variant';

var select_id = "";
var select_id2 = "";

interface VariantTableData {
  searchResults: GridRowsProp;
  isverified: boolean | null;
  selectedrowid : string;
  createVSList(row: Variant) : void;
  opendetail(row: Variant, type:string): void;
}


export default function VariantTable_MUI(props: VariantTableData) {
  const apiRef = useGridApiRef();
  
  const VariantResultColumns: GridColDef[] = [
    {
      field: 'Position',
      headerName: 'Position',
      sortable: true,
      width: 80,
      renderCell: (params) =>
        <a className="link-primary" onClick={() => { props.opendetail(params.row, 'all') }}>{params.row.Position}</a>,
    },
    {
      field: 'RefAllele_display',
      headerName: 'Ref Allele',
      sortable: true,
      width: 150
    },
    {
      field: 'RefCount',
      headerName: 'Ref Count',
      sortable: true,
      width: 80,
      renderCell: (params) =>
        <a className="link-primary" onClick={() => { props.opendetail(params.row, '0/0') }}>{params.row.RefCount}</a>,
    },
    {
      field: 'AltAllele_display',
      headerName: 'Alt Allele',
      sortable: true,
      width: 80,
    },
    {
      field: 'AltCount',
      headerName: 'Alt Count',
      sortable: true,
      width: 80,
      renderCell: (params) =>
        <a className="link-primary" onClick={() => { props.opendetail(params.row, '1/1') }}>{params.row.AltCount}</a>,
    },
    {
      field: 'MissingCount',
      headerName: 'Missing Count',
      sortable: true,
      width: 80,
      renderCell: (params) =>
        <a className="link-primary" onClick={() => { props.opendetail(params.row, '.') }}>{params.row.MissingCount}</a>,
    },
    {
      field: 'Impact',
      headerName: 'Impact',
      sortable: true,
      width: 120,
    },
    {
      field: 'NTChange_display',
      headerName: 'NT Change',
      sortable: true,
      width: 80,
    },
    {
      field: 'AAChange_display',
      headerName: 'AA Change',
      sortable: true,
      width: 80,
    },
    {
      field: 'GeneName_display',
      headerName: 'Gene Name',
      sortable: true,
      width: 80,
    },
    {
      field: 'GeneID_display',
      headerName: 'Gene ID',
      sortable: true,
      width: 80,
      renderCell: (params) =>
        <a className="link-primary" onClick={() => { popup_multipHyperlink(params.row.GeneID, params.row.RefseqID, 'Gene'); }}>{params.row.GeneID_display}</a>,
    },
    {
      field: 'ProteinID',
      headerName: 'Protein ID',
      sortable: true,
      width: 80,
      renderCell: (params) =>
        <a className="link-primary" onClick={() => { popup_multipHyperlink(params.row.ProteinID, '', 'Protein'); }}>{params.row.ProteinID}</a>
    },
    {
      field: 'ProteinName',
      headerName: 'Protein Name',
      sortable: true,
      width: 100,
    },
    {
      field: 'cohort',
      type: "actions",
      headerName: 'Variant List',
      width: 120,
      renderCell: (params) =>{
        if (params.row.VSList === '') {
          return <a className="link-primary" onClick={() => { props.createVSList(params.row); }}>Create Sample List</a>
        }
        else {
          return <span >{params.row.VSList}</span>
        }
      }
    },
  ];


const getTogglableColumns = () => {
  if(props.isverified){
    return VariantResultColumns;
  }
  else{
    return VariantResultColumns
      .filter((column) => column.field !== "cohort");
  }
};

  const popup_multipHyperlink = function (g_id: string, id2: string, grouptype: string) {
    select_id2 = "";
    select_id = "";

    $(".custom-menu").finish().toggle(100).css({
      top: (window.event as MouseEvent).pageY + "px",
      left: (window.event as MouseEvent).pageX + "px"
    }); // In the right position (the mouse)

    $('.custom-menu li').hide();
    $('.custom-menu li').filter('[data-label=' + grouptype + ']').show();

    if (id2 != '') {
      select_id2 = id2.split("-")[0];
    }

    var first_id = g_id.split("-");

    select_id = first_id[0];
  }


  const gotoextern = function (target: string) {
    switch (target) {
      case "ncbi_gene":
        window.open("https://www.ncbi.nlm.nih.gov/gene?cmd=search&term=" + select_id, "_blank");
        break;
      case "geo":
        window.open("https://www.ncbi.nlm.nih.gov/geoprofiles?term=" + select_id, "_blank");
        break;
      case "kegg":
        window.open("https://www.genome.jp/dbget-bin/www_bfind_sub?dbkey=genes&keywords=" + select_id + "&mode=bfind&max_hit=100", "_blank");
        break;
      case "ncbi_protein":
        window.open("https://www.ncbi.nlm.nih.gov/protein/" + select_id, "_blank");
        break;
      case "uniprot":
        window.open("https://www.uniprot.org/uniprot/?query=" + select_id, "_blank");
        break;
      case "tbrnat":
        window.open("https://bioinformatics.niaid.nih.gov/tbrnat/?term=" + select_id2, "_blank");
        break;
      default:
        break;
    }
  }

  const handleClickOutside = () => {
    $(".custom-menu li").hide();
    $(".custom-menu").hide();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

    return function () {
      document.addEventListener('click', handleClickOutside, true);
    }
  }, [props.selectedrowid]);

  return (
    <div>
      <div>
        <div style={{ width: '100%' }}>
          <DataGrid
            rows={props.searchResults}
            columns={getTogglableColumns()}
            apiRef={apiRef}

            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}

            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              sorting: {
                sortModel: [{ field: 'P', sort: 'asc' }],
              },
            }}

            slotProps={{
              pagination: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}

            pageSizeOptions={[10, 25, 50, 100]}

            sx={{ ".MuiDataGrid-cell": { p: 1 } }}
          />
        </div>

      </div>
      <div>
        <ul className='custom-menu'>
              <li data-label="Gene"><a onClick={() => gotoextern('ncbi_gene')} >NCBI Gene Database</a></li>
              <li data-label="Gene"><a onClick={() => gotoextern('geo')} >NCBI Gene Expression Omnibus</a></li>
              <li data-label="Gene"><a onClick={() => gotoextern('kegg')} >KEGG Genes Database</a></li>
              <li data-label="Gene"><a onClick={() => gotoextern('tbrnat')} >TBRNAT</a></li>
              <li data-label="Protein"><a onClick={() => gotoextern('ncbi_protein')} >NCBI Protein</a></li>
              <li data-label="Protein"><a onClick={() => gotoextern('uniprot')} >UniProt</a></li>
        </ul>
      </div>
    </div>
  );
};
