import * as React from "react";
import { UISref, Transition, isNullOrUndefined } from "@uirouter/react";
import BlockUi from '@availity/block-ui';
import { DNA } from  'react-loader-spinner';
import Select, {SingleValue, createFilter} from 'react-select';
import SearchableListData from "../../../domain/SearchableListData";
import GenePosition from "../../../domain/GenePosition";
import SampleList from "../../../domain/SampleList";
import axios from "axios";
import API_URL from '../../../services/Enviroment';
import UserSession from "../../../services/UserSession";
import Cohort from "../../../domain/Cohort";
import {removeOrcidCode} from '../../utils/gapCommon';
import "./TBPortalsData.css"
import TBPortalTable_MUI from "./TBPortalTable_MUI";

type TBPortalState = {
  blocking: boolean,
  sampletype: string,
  errors: string,
  selectedSampleid?: SearchableListData,
  selectedSamplelist: string,
  selectedCohort: string,
  sampleresultfound: boolean,
  samplelistresultfound: boolean,
  Jasonchecked: boolean,
  Textchecked: boolean
}

const SampleColumns = [
  {
    dataField: 'id', text: 'Sample ID', sort: true, headerAlign: 'center',
    headerStyle: { verticalAlign: 'middle', width: '80px' },
    headerClasses: "tablecellwrap", classes: 'tablecellwrap'
  },
  {
    dataField: 'endid', text: 'Sample ID', sort: true, headerAlign: 'center',
    headerStyle: { verticalAlign: 'middle', width: '150px' },
    headerClasses: "tablecellwrap", classes: 'tablecellwrap'
  },
  {
    dataField: 'value', text: 'Edit Distance', sort: true, headerAlign: 'center',
    headerStyle: { verticalAlign: 'middle', width: '150px' },
    headerClasses: "tablecellwrap", classes: 'tablecellwrap'
  },
];

export default class TBPortalsData extends React.Component<Transition, TBPortalState> {
  usersession = UserSession.getUserSession();
  samplename = "";
  cohorts = Array<Cohort>();
  sampleids = Array<SearchableListData>();
  sampleLists = Array<SampleList>();

  sampleresult_Text = "";
  sampleresult_Json = "";
  samplelistresult_Text = "";
  samplelistresult_Json = "";

  sampleListData = Array();

  constructor(props: Transition) {
    super(props);
   
    this.state = {
      blocking: false,
      errors: "",
      sampletype: "s",
      selectedSampleid: undefined,
      selectedSamplelist: "",
      selectedCohort: "",
      sampleresultfound: false,
      samplelistresultfound : false,
      Jasonchecked: false,
      Textchecked: false,
    };
  }

  getSampleIds = () => {
    var stateJson = {
      blocking:true,
      errors:""
    } as TBPortalState;

    this.setState(stateJson);

    let url = API_URL + 'prediction/getSampleIds';
    axios.get(url)
      .then(response => {
        var namepositions = response.data as Array<GenePosition>;
				this.sampleids = Object.entries(namepositions).map(([key, value], i) => {
					return {"value": value.Name, "label": value.Name}
				});
        this.loadUserSampleList(stateJson);
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
      });
  }

  loadUserSampleList = (stateJson: TBPortalState) => {
    if (this.usersession.isverified) {
      let url = API_URL + 'listmanagement/getList';
      axios.get(url)
        .then(response => {
          this.sampleLists = response.data;
          stateJson.blocking = false;
          this.setState(stateJson);
        })
        .catch(error => {
          console.error('There was an error!' + error.message);
          this.setState({ errors: 'There was an error!' + error.message, blocking: false });
        });
    }
    else {
      stateJson.blocking = false;
      this.setState(stateJson);
    }
  }

  onSamppletypeChange =  (event: React.FormEvent<HTMLInputElement>) => {
    var target = event.target as HTMLInputElement;
    this.setState({sampletype:target.value});
  }

  onReloadCohorts = () => {   //will add a call to get latest cohorts from DEPOT.
    if (this.usersession.isverified) {
      this.setState({ blocking: true});
      let url = API_URL + 'genomics/reloadDepotData';
      axios.post(url, { email: this.usersession.email })
        .then(response => {
          this.setState({ blocking: false });
          this.usersession.Depot_cohorts = response.data;
          sessionStorage.setItem("userSession", JSON.stringify(this.usersession));
          this.cohorts = this.usersession.Depot_cohorts;
        })
        .catch(error => {
          console.error('There was an error!' + error.message);
          this.setState({ errors: 'There was an error!' + error.message, blocking: false });
        });
    }
  }

  onChangeSampleId = (option: SingleValue<SearchableListData>) => {
    if (option == null) {
      this.setState({ selectedSampleid: undefined });
    }
    else {
      this.samplename = option?.label;
      this.setState({ selectedSampleid: { value: option?.value, label: option?.label } });
    }
  }

  onChangeList =(event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;
		this.setState ({selectedSamplelist: target.value});
	}

	onChangeCohort=(event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;
		this.setState ({selectedCohort: target.value});
	}

  onTextchecked =(event: React.FormEvent<HTMLInputElement>) => {
		var textchecked = !this.state.Textchecked;
		this.setState({Textchecked: textchecked});
	}

  onJasonchecked =(event: React.FormEvent<HTMLInputElement>) => {
		var jasoncheck = !this.state.Jasonchecked;
		this.setState({Jasonchecked: jasoncheck});
	}

  tbProfilerSampleResults = () => {
    var sampleiDNAme = "";
    if (this.state.sampletype == 's') {
      if (this.state.selectedSampleid == undefined) return;
      sampleiDNAme = this.state.selectedSampleid.value;
    }
    else if (this.state.sampletype == 'c') {    //cohort sample
      var cohorsdata = JSON.parse(this.state.selectedCohort) as Cohort;
      sampleiDNAme = cohorsdata.ssrids;
    }
    else if (this.state.sampletype == 'l') {
      var listdata = JSON.parse(this.state.selectedSamplelist) as SampleList;
      sampleiDNAme = listdata.totallistcontent;
    }
    else {
      return;
    }

    var stateJson = {
      blocking: true,
      errors: ""
    } as TBPortalState;

    this.setState(stateJson);

    let url = API_URL + 'prediction/getSampleResult';
    var tbportaldata = {
      "tbprofilerType": this.state.sampletype,
      "sampleid": sampleiDNAme
    };
    axios.post(url, tbportaldata)
      .then(response => {
        stateJson.blocking = false;
        if(response.data != undefined){
    			if(Object.keys(response.data.map).length > 0){
    				
	    			if(this.state.sampletype !='s'){
	    				this.samplelistresult_Text = response.data.map.Text;   //orginal result
		    			this.samplelistresult_Json = response.data.map.Json;
	    			  this.sampleListresultDisplay(stateJson);
	    			}
	    			else{
	    				this.sampleresult_Text = response.data.map.Text;   //orginal result
		    			this.sampleresult_Json = response.data.map.Json;
	    				stateJson.sampleresultfound = true;
	    				stateJson.samplelistresultfound = false;
	    			}
    			}
    			else{
            stateJson.errors = "No result";
    			}
    		}
        this.setState(stateJson);
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
        stateJson.blocking = false;
        stateJson.errors = error.message;
        this.setState(stateJson);
      });
  }

  sampleListresultDisplay =(stateJson:TBPortalState) =>{
		stateJson.sampleresultfound = false;
		stateJson.samplelistresultfound = true;
		
		this.sampleListData = [];
		var json_data = JSON.parse(this.samplelistresult_Json);
    var rowid = 0;
		for (var sampleid in json_data) {
      rowid ++;
			var rowdata : { [key: string]: string }={};
			rowdata["id"] = rowid.toString();
			rowdata["SampleId"] = sampleid;
			var sampleobj = json_data[sampleid];
			for (var o in sampleobj) {
				rowdata[o]  = sampleobj[o];
			}
			
			this.sampleListData.push(rowdata);
		}
  }

  getsampleresult =(sampleid:string) => {
    this.samplename = sampleid;
    var stateJson = {
      blocking: true,
      errors: ""
    } as TBPortalState;

    let url = API_URL + 'prediction/getSampleResult';
    var tbportaldata = {
      "tbprofilerType": 's',
      "sampleid": sampleid
    };
    axios.post(url, tbportaldata)
      .then(response => {
        	this.sampleresult_Text = response.data.map.Text;   //orginal result
			    this.sampleresult_Json = response.data.map.Json;
			    stateJson.sampleresultfound = true;
          stateJson.blocking = false;
          this.setState(stateJson);
    })
    .catch(error => {
      console.error('There was an error!' + error.message);
      stateJson.blocking = false;
      stateJson.errors = error.message;
      this.setState(stateJson);
    });
  }

  componentDidMount(){
    this.getSampleIds();
  }

  render() {
    removeOrcidCode("tbportalsdata", this.props);
    this.usersession = JSON.parse(sessionStorage.userSession);
    this.cohorts =  this.usersession.Depot_cohorts;
    return (
      <BlockUi tag="div" blocking={this.state.blocking} loader={<DNA visible={true} height="80" width="80" ariaLabel="DNA-loading" wrapperStyle={{}} wrapperClass="DNA-wrapper"/>}>
        <div className="gap-page-content">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className='breadcrumb-item'>
                  <UISref to="home"><a>Home</a></UISref>
                </li>
                <li className='breadcrumb-item'>
                  <UISref to="resistance"><a>Resistance</a></UISref>
                </li>
                <li className="breadcrumb-item active" aria-current="page">TB Portals data</li>
              </ol>
            </nav>
            <h1>Resistance Prediction and Lineage Information</h1>
            <div>
              TBProfiler results for the TB Portals genomic data.
            </div>
            <hr />
            <form name="TBPortalDataForm">
              {this.usersession.isverified &&
                <div className="row" >
                  <div className="col-md-4">
                    <input id="cb_sample" type="radio" name="sampletype" value="s" checked={this.state.sampletype === "s"}
                      onChange={this.onSamppletypeChange} />
                    <span className="margin-left-10" >
                      <label htmlFor="cb_sample">Sample ID</label>
                    </span>
                  </div>
                  <div className="col-md-4">
                    <input id="cb_list" type="radio" name="sampletype" value="l" checked={this.state.sampletype === "l"}
                      onChange={this.onSamppletypeChange} />
                    <span className="margin-left-10" >
                      <label htmlFor="cb_list">Sample List</label>
                    </span>
                  </div>
                  {/* <div className="col-md-4">
                    <input id="cb_cohort" type="radio" name="sampletype" value="c" checked={this.state.sampletype === "c"}
                      onChange={this.onSamppletypeChange} />
                    <span className="margin-left-10" >
                      <label htmlFor="cb_cohort">Cohorts&nbsp;&nbsp;</label>
                    </span>
                    <a onClick={() => this.onReloadCohorts()} >Reload Cohorts</a>
                  </div> */}
                </div>
              }

              {!this.usersession.isverified &&
                <div className="margin-bottom-10">
                  <div className="row">
                    <div className='col-md-6'>
                      <label>Sample ID</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row col-md-12">
                        <div className="form-group margin-top-10">
                          <Select
                            inputId="sampleid"
                            options={this.sampleids}
                            value={this.sampleids.filter(({ value }) => value === this.state.selectedSampleid?.value)}
                            onChange={(option) => this.onChangeSampleId(option)}
                            isDisabled={this.state.sampletype != 's'}
                            isSearchable={true}
                            isClearable={true}
                            filterOption={createFilter({ ignoreAccents: false })}
                            placeholder={"Select Sample Id ..."} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="row">
              {this.usersession.isverified &&
                <div className="col-md-4">
                  <fieldset className="custom_fieldset" disabled={this.state.sampletype != 's'}>
                    <div className="row col-md-12">
                      <div className="form-group margin-top-10">
                        <Select
                          inputId="sampleid"
                          options={this.sampleids}
                          value={this.sampleids.filter(({ value }) => value === this.state.selectedSampleid?.value)}
                          onChange={(option) => this.onChangeSampleId(option)}
                          isDisabled={this.state.sampletype != 's'}
                          isSearchable={true}
                          isClearable={true}
                          filterOption={createFilter({ ignoreAccents: false })}
                          placeholder={"Select Sample Id ..."} />
                      </div>
                    </div>
                  </fieldset>
                </div>
                }
                {this.usersession.isverified &&
                  
                  <div className="col-md-4">
                    <fieldset className="custom_fieldset" disabled={this.state.sampletype != 'l'}>
                      <div className="form-group margin-top-10" >
                        <select
                          id="sampleList"
                          className="form-select"
                          value={this.state.selectedSamplelist}
                          onChange={this.onChangeList}>
                          <option value="">Select a Sample List ...</option>
                          {
                            Object.entries(this.sampleLists).map(([key, value], i) => (
                              <option key={key} value={JSON.stringify(value)}>{value.listname}</option>
                            ))
                          }
                        </select>
                      </div>
                    </fieldset>
                  </div>
                }
                {/* {this.usersession.isverified &&
                  <div className="col-md-4">
                    <fieldset className="custom_fieldset" disabled={this.state.sampletype != 'c'}>
                      <div className="form-group margin-top-10">
                        <select
                          id="cohort_sampleList"
                          placeholder="Select a Cohort ..."
                          className="form-select"
                          value={this.state.selectedCohort}
                          onChange={this.onChangeCohort}>
                          <option value="">Select a Cohort ...</option>
                          {
                            Object.entries(this.cohorts).map(([key, value], i) => (
                              <option key={key} value={JSON.stringify(value)}>{value.name}</option>
                            ))
                          }
                        </select>
                      </div>
                    </fieldset>
                  </div>
                } */}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <button id="search" type="button" onClick={this.tbProfilerSampleResults}
                    className=" btn btn-primary"
                    disabled={(this.state.sampletype == 's' && this.state.selectedSampleid == undefined) ||
                      (this.state.sampletype == 'c' && this.state.selectedCohort == '') ||
                      (this.state.sampletype == 'l' && this.state.selectedSamplelist == '')
                    }>Results
                  </button>
                </div>
              </div>

                <div className="tablelay">
                  <hr></hr>
                  <TBPortalTable_MUI
                      searchResults={this.sampleListData} 
                      sampletype = {this.state.sampletype}
                      selectedSampleid = {this.samplename}
                      selectedCohort = {this.state.selectedCohort}
                      selectedSamplelist = {this.state.selectedSamplelist}
                      sampleresult_Text = {this.sampleresult_Text}
                      sampleresult_Json = {this.sampleresult_Json}
                      samplelistresult_Text ={this.samplelistresult_Text}
                      samplelistresult_Json = {this.samplelistresult_Json}
                      sampleresultfound={this.state.sampleresultfound} 
                      samplelistresultfound={this.state.samplelistresultfound} 
                      getsampleresult= {(sampleid: string) => this.getsampleresult(sampleid)} />
                  
                </div>
            </form>
          </div>
        </div>
      </BlockUi >
    );
  }
}