import React, { ChangeEvent, useState } from 'react';
import { DataGrid, GridColDef, GridRowsProp, useGridApiRef } from '@mui/x-data-grid';


interface TBPortalTableData {
  searchResults: GridRowsProp;
  sampletype: string;
  selectedSampleid: string;
  selectedCohort: string;
  selectedSamplelist: string;
  sampleresult_Text: string;
  sampleresult_Json: string;
  samplelistresult_Text: string;
  samplelistresult_Json: string;
  sampleresultfound: boolean;
  samplelistresultfound: boolean;
  getsampleresult(sampleid: string) : void;
}

export default function TBPortalTable_MUI (props : TBPortalTableData) {

  const [showtbprofiler, setshowtbprofiler] = useState(false);
  const [disabledcompare, setdisabledcompare] = useState(false);
  const [Jasonchecked, setJasonchecked] = useState(false);
  const [Textchecked, setTextchecked] = useState(false);
  const [JasoncheckedList, setJasoncheckedList] = useState(false);
  const [TextcheckedList, setTextcheckedList] = useState(false);
  const [TbProfileResults, setTbProfileResults] = useState("");
  
  const TBPortalResultColumns: GridColDef[] = [
    {
      field: 'SampleId', headerName: 'Sample', sortable: true, width: 110,
      renderCell: (params) =>
        <a className="link-primary" onClick={() => { props.getsampleresult(params.row.SampleId); }}>{params.row.SampleId}</a>,
    },
    { field: 'main_lin', headerName: 'main_lineage', sortable: true, width: 110 },
    { field: 'drtype', headerName: 'DR_type', sortable: true, width: 80 },
    { field: 'num_dr_variants', headerName: 'num_dr_variants', sortable: true, width: 80 },
    { field: 'num_other_variants', headerName: 'num_other_variants', sortable: true, width: 80 },
    { field: 'rifampicin', headerName: 'rifampicin', sortable: true, width: 80 },
    { field: 'isoniazid', headerName: 'isoniazid', sortable: true, width: 80 },
    { field: 'pyrazinamide', headerName: 'pyrazinamide', sortable: true, width: 80 },
    { field: 'ethambutol', headerName: 'ethambutol', sortable: true, width: 80 },
    { field: 'streptomycin', headerName: 'streptomycin', sortable: true, width: 80 },
    { field: 'fluoroquinolones', headerName: 'fluoroquinolones', sortable: true, width: 80 },
    { field: 'moxifloxacin', headerName: 'moxifloxacin', sortable: true, width: 80 },
    { field: 'ofloxacin', headerName: 'ofloxacin', sortable: true, width: 80 },
    { field: 'levofloxacin', headerName: 'levofloxacin', sortable: true, width: 80 },
    { field: 'ciprofloxacin', headerName: 'ciprofloxacin', sortable: true, width: 80 },
    { field: 'aminoglycosides', headerName: 'aminoglycosides', sortable: true, width: 80 },
    { field: 'amikacin', headerName: 'amikacin', sortable: true, width: 80 },
    { field: 'kanamycin', headerName: 'kanamycin', sortable: true, width: 80 },
    { field: 'capreomycin', headerName: 'capreomycin', sortable: true, width: 80 },
    { field: 'ethionamide', headerName: 'ethionamide', sortable: true, width: 80 },
    { field: 'para-aminosalicylic_acid', headerName: 'para-aminosalicylic_acid', sortable: true, width: 80 },
    { field: 'linezolid', headerName: 'linezolid', sortable: true, width: 80 },
    { field: 'bedaquiline', headerName: 'bedaquiline', sortable: true, width: 80 },
    { field: 'clofazimine', headerName: 'clofazimine', sortable: true, width: 80 },
    { field: 'delamanid', headerName: 'delamanid', sortable: true, width: 80 },
  ];

    const DownloadSampleListResult =() =>{
      var downloadfilename = "";
      var file: Blob;
      if (props.sampletype === "l") {
        var listjson = JSON.parse(props.selectedSamplelist);
        downloadfilename = listjson.listname;
      } else if (props.sampletype === "c") {
        var cohortjson = JSON.parse(props.selectedCohort);
        downloadfilename = cohortjson.name;
      } else {
        return;
      }

      if (TextcheckedList) {
        file = new Blob([props.samplelistresult_Text], {
          type: "application/txt",
        });
        downloaddata(file, downloadfilename + "_Text");
      }

      if (JasoncheckedList) {
        file = new Blob([props.samplelistresult_Json], {
          type: "application/txt",
        });

        if(TextcheckedList){
          setTimeout(() => {downloaddata(file, downloadfilename + "_Json");}, 500);
        }
        else{
          downloaddata(file, downloadfilename + "_Json");
        }
      }
    }

    const DownloadSampleResult = () => {
        var downloadfilename = props.selectedSampleid;
        var file: Blob;
       

        if (Textchecked) {
          file = new Blob([props.sampleresult_Text], {
            type: "application/txt",
          });
          downloaddata(file, downloadfilename + "_Text");
        }

        if (Jasonchecked) {
          file = new Blob([props.sampleresult_Json], {
            type: "application/txt",
          });

          if(Textchecked){
            setTimeout(() => {downloaddata(file, downloadfilename + "_Json");}, 500);
          }
          else{
            downloaddata(file, downloadfilename + "_Json");
          }
        }
    }

    const downloaddata =(file: Blob, downloadfilename: string) =>{
        //trick to download store a file having its URL
        var fileURL = URL.createObjectURL(file);
        var a         = document.createElement('a');
        a.href        = fileURL; 
        a.target      = '_blank';
        a.download    = downloadfilename;
        document.body.appendChild(a);
        a.click();
    }

    const onTextchecked =(event: ChangeEvent<HTMLInputElement>) => {
      setTextchecked(event.target.checked);
	  }

    const onJasonchecked =(event: ChangeEvent<HTMLInputElement>) => {
      setJasonchecked(event.target.checked);
	  }

    const onListTextchecked =(event: ChangeEvent<HTMLInputElement>) => {
      setTextcheckedList(event.target.checked);
	  }

    const onListJasonchecked =(event: ChangeEvent<HTMLInputElement>) => {
      setJasoncheckedList(event.target.checked);
	  }

        return (
          <div>
            {props.samplelistresultfound && (
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      id="cb_text"
                      type="checkbox"
                      name="cb_text"
                      checked={TextcheckedList}
                      onChange={onListTextchecked}
                    />
                    <label htmlFor="cb_text">&nbsp; headerName </label>
                    <input
                      id="cb_text"
                      className="margin-left-10"
                      type="checkbox"
                      name="cb_text"
                      checked={JasoncheckedList}
                      onChange={onListJasonchecked}
                    />
                    <label htmlFor="cb_text">&nbsp; JSON</label>
                    <button
                      id="singledownloadtable"
                      type="button"
                      className="btn btn-success margin-left-10"
                      disabled={
                        !TextcheckedList && !JasoncheckedList
                      }
                      onClick={DownloadSampleListResult}
                    >
                      <span className="glyphicon glyphicon-save"></span>Download
                    </button>
                  </div>
                </div>
                <div>
                
              </div>

              <div className='row' style={{ width: '100%' }}>
                <DataGrid 
                  rows={props.searchResults} 
                  columns={TBPortalResultColumns}  
                 
                  getRowHeight={() => 'auto'}
                  getEstimatedRowHeight={() => 200}

                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 10, page: 0 },
                    },
                  }}

                  slotProps={{
                      pagination: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                  }}

                  pageSizeOptions={[10, 25, 50, 100]}
                  sx={{ ".MuiDataGrid-cell": {p: 1} }}
                />
              </div>
               
                <hr></hr>
              </div>
            )}
            {props.sampleresultfound && (
              <div className=" margin-top-10">
                <div className="row">
                 
                  <div className="col-md-6">
                    <input
                      id="cb_text"
                      type="checkbox"
                      name="cb_text"
                      checked={Textchecked}
                      onChange={onTextchecked}
                    />
                    <label htmlFor="cb_text">&nbsp; headerName </label>
                    <input
                      id="cb_text"
                      className="margin-left-10"
                      type="checkbox"
                      name="cb_text"
                      checked={Jasonchecked}
                      onChange={onJasonchecked}
                    />
                    <label htmlFor="cb_text">&nbsp; JSON</label>
                    <button
                      id="singledownloadtable"
                      type="button"
                      className="btn btn-success margin-left-10"
                      disabled={
                        !Textchecked && !Jasonchecked
                      }
                      onClick={DownloadSampleResult}
                    >
                      <span className="glyphicon glyphicon-save"></span>Download
                    </button>
                  </div>
                </div>

                <div className="row margin-top-10">
                  <br />
                  <pre className="preOut">
                    <code>{props.sampleresult_Text}</code>
                  </pre>
                </div>
              </div>
            )}
          </div>
        );
};