import * as React from "react";
import BlockUi from '@availity/block-ui';
import { DNA } from  'react-loader-spinner';
import UserSession from '../../../services/UserSession'
import Modal from 'react-bootstrap/Modal'
import axios from "axios";
import VCF from '../../../domain/VCF'
import Plotly from './ClusteringPlotly';
import Vis3d from './ClusteringVisGraph3D';
import SampleList from "../../../domain/SampleList"
import Cohort from "../../../domain/Cohort"
import PlinkRowData from "../../../domain/PlinkRowData";
import { UISref, Transition } from "@uirouter/react";
import API_URL from '../../../services/Enviroment'
import SearchableListData from "../../../domain/SearchableListData"
import {removeOrcidCode} from '../../utils/gapCommon';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import UserGroup from "../../../domain/UserGroup";
//import UserGroupTable from '../../UserProfiler/UserProfileTable_old';
import './Clustering.css'
import GroupTable_MUI from "../../UserProfiler/GroupTable_MUI";

type ClusteringState = {
	blocking: boolean,
	ssridtype: string,
	depotsupport: boolean,
	selectedvcffile: string,
	selectedSamplelist: string,
	selectedCohort: string,
	colorDD: string,
	sortlegend: string;
	metadataChanged: boolean;
	resultfound: boolean,
	blockmessage: string,
	totalSampleids: SearchableListData[],
	selectedDualListSampleids: string[],
	errors: string,
	showModal: boolean,
	hasModalerror: boolean,
	Modalerrors: string,
	listname: string,
	refreshflag: number
}

type PlinkResult = {
    rowdata: PlinkRowData
}

export default class Clustering extends React.Component<Transition, ClusteringState> {
	usersession = UserSession.getUserSession();
	vcffiles = Array<VCF>();
	selectedVCFfilename = "";
	allGroups =  Array<UserGroup>();
	selectedModalGroups=new Array();
	sampleLists = Array<SampleList>();
	sampleids = Array<SearchableListData>();

	colorTypes: { [key: string]: String } = {};
	cohorts = Array<Cohort>();
	clusteringResult = Array<PlinkRowData>();
	downloadResult = Array<PlinkRowData>();
    verified_listclass="col-md-6";
    all_listClass="col-md-3 allClass";
    sample_listClass="col-md-6";
	constructor(props: Transition) {
		super(props);
		if (sessionStorage.token == null || sessionStorage.token == "") {
			let transition = props;
			const $state = transition.router.stateService;
			$state.go('home');
		}

		this.state = {
			blocking: false,
			ssridtype: "a",
			depotsupport: true,
			selectedvcffile: "",
			selectedCohort: "",
			colorDD: "",
			metadataChanged: false,
			sortlegend: "n",
			selectedSamplelist: "",
			resultfound: false,
			blockmessage: "Loading",
			totalSampleids: [],
			selectedDualListSampleids: [],
			errors: "",
			showModal: false,
			hasModalerror: false,
			Modalerrors: "",
			listname: "",
			refreshflag : 1
		};
	}

	onChangeList =(event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;
		this.setState ({selectedSamplelist: target.value});
	}

	onChangeCohort=(event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;

		this.setState ({selectedCohort: target.value, metadataChanged: true});
	}

	onSsridtypeChange = (event: React.FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ssridtype:target.value});
    }

	onReloadCohorts =() => {   //will add a call to get latest cohorts from DEPOT.
		if(this.usersession.isverified){
            this.setState({blocking: true, blockmessage:"Refresh cohorts..."}); 
            let url = API_URL + 'genomics/reloadDepotData';
            axios.post(url, {email:this.usersession.email})
                .then(response => {
                    this.setState({blocking: false}); 
                    this.usersession.Depot_cohorts = response.data;
	        	    sessionStorage.setItem("userSession", JSON.stringify(this.usersession));
                })
                .catch(error => {
                    console.error('There was an error!' + error.message);
                    this.setState ({errors: 'There was an error!' + error.message, blocking: false});
                });
		}
	}

	onChangevcf = (event: React.FormEvent<HTMLSelectElement>) => {
		var target = event.target as HTMLSelectElement;
      
		var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
			selectedvcffile: target.value,
            errors:""
        } as ClusteringState;

		this.setState(stateJson);

    	this.clusteringResult = [];
		var selectedVCFfile = JSON.parse(target.value) as VCF;
		var vcffilename = selectedVCFfile.filename;
		if (selectedVCFfile.owner != null && selectedVCFfile.owner != "system") {
			vcffilename = selectedVCFfile.owner + "/" + vcffilename;
		}
		this.selectedVCFfilename = vcffilename;
		this.loadSampleList(stateJson);
	}

	onChangeColor =(event: React.FormEvent<HTMLSelectElement>) => {
		let target = event.target as HTMLSelectElement;
		let name = target.options[target.options.selectedIndex].text;
		this.setState ({colorDD: name});
	}

	onSortLegndChange =(event: React.FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({sortlegend:target.value});
    }

	loadvcffile = () => {
        var stateJson = {
            blocking:true,
            blockmessage:"Loading ... ",
            resultfound: false,
			metadataChanged: false,
            errors:""
        } as ClusteringState;

        this.setState(stateJson);

        let url = API_URL +'genomics/VCFfiles';
        axios.post(url, {type:'vcf'})
            .then(response => {
                this.vcffiles = response.data;
                var defaultvcffile=this.vcffiles[0];
                for (var i = 0; i < this.vcffiles.length; i++) {
                    var vcf = this.vcffiles[i];
                    if (vcf.isdefault) {
                        defaultvcffile = vcf;
                        break;
                    }
                }

				var vcffilename = defaultvcffile.filename;
				if (defaultvcffile.owner != null && defaultvcffile.owner != "system") {
					vcffilename = defaultvcffile.owner + "/" + vcffilename;
				}

				this.selectedVCFfilename = vcffilename;
                stateJson.selectedvcffile = JSON.stringify(defaultvcffile);

				if(this.usersession.isverified){
					this.loadusergroup(stateJson);
				}
				else{
					this.loadSampleList(stateJson);
				}
				
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.blocking = false;
                stateJson.errors = error.message;
            });
    }

	loadusergroup =(stateJson: ClusteringState) =>{
        let url = API_URL +'user/getUserGroups';
        axios.post(url, {isadmin: this.usersession.isadminuser, email: this.usersession.email})
            .then((response) => {
                if(response.status == 200 ){
                    this.allGroups = response.data;
					this.loadSampleList(stateJson);
                }
            })
            .catch((error) => {
                console.error("There was an error!" + error.message);
                stateJson.blocking = false;
                this.setState(stateJson);
            });
    }

	loadSampleList =(stateJson: ClusteringState) => {
		var selectedvcf = JSON.parse(stateJson.selectedvcffile) as VCF;
		let url = API_URL +'listmanagement/getList';
        axios.get(url, {params: {vcfid: selectedvcf.vcfid}})
            .then((response) => {
				var userlist = response.data;
        		var userSampleList = userlist.filter(function(list : SampleList){
        			return (list.listtype == 'Sample');
        		});
        		
                this.sampleLists = userSampleList;

				if(this.usersession.isverified){
					if(selectedvcf.hasmetadata){
						stateJson.depotsupport = true;
					}
					else{
						stateJson.depotsupport = false;
						stateJson.ssridtype="a";
					}
				}
				
				stateJson.blocking = false;
                this.setState(stateJson);
            })
            .catch((error) => {
                console.error("There was an error! " + error.message);
                this.setState({errors:error.message});
            });
	}

	OnSearchData =() => {
        if(this.state.ssridtype == 'a'){
            this.getVisualizationResults("all");
        }
		else if(this.state.ssridtype == 's'){
			var selectedList = JSON.parse(this.state.selectedSamplelist) as SampleList;
			var controlsdata = selectedList.totallistcontent;
			this.getVisualizationResults(controlsdata);
		}
		else {
			if(this.state.selectedCohort  != null && this.state.selectedCohort != ""){
				var controlsdata = (JSON.parse(this.state.selectedCohort) as Cohort).ssrids;
				this.getVisualizationResults(controlsdata);
			}
		}
	}

	OnCreateList =() =>{
		this.setState({ showModal: true, hasModalerror: false});
	}

	createCohort = () => {
		var stateJson = {
			blocking: true,
			errors: ""
		} as ClusteringState;
		var listcontent = this.state.selectedDualListSampleids;

		let url = API_URL + 'genomics/createCohorts';
		axios.post(url, {
			"ref_sampleIds": this.state.selectedDualListSampleids,
			"alt_sampleIds": [],
			"cohort1": this.state.listname,
			"cohort2": ""
		})
		.then(response => {
			var returnval = response.data;
			var cohortName = returnval.cohortname;
			var failedCohorts = returnval.failedCohorts;
			stateJson.blocking = false;
			this.setState(stateJson);
		})
		.catch(error => {
			console.error('There was an error!' + error.message);
			stateJson.blocking = false;
			stateJson.errors = error.message;
			this.setState(stateJson);
		});
	}

	onModalSave = () => {
        if(this.state.listname == undefined){
            return;
        }

		var stateJson = {
            blocking:true,
        } as ClusteringState;
        this.setState (stateJson);

		var selectedGroupIds: { [key: string]: boolean } = {};
        this.selectedModalGroups.forEach(groupid=>{
            selectedGroupIds[groupid] =true;
        });

		var selectedvcfs = Array<VCF>();
		var selectedvcf = JSON.parse(this.state.selectedvcffile) as VCF;
        selectedvcfs.push(selectedvcf);
		var listcontent = this.state.selectedDualListSampleids.toString();
		listcontent = listcontent.replace(/,/g, '\n');

        let url = API_URL + 'listmanagement/saveList';
        axios.post(url, { 
            listcontent: listcontent,
            listName: this.state.listname,
            listtype: "Sample",
			listsource: "Clustering",
			vcfList: JSON.stringify(selectedvcfs),
            groupsIds: JSON.stringify(selectedGroupIds)
        }) 
        .then(response => {
            if(response.status == 200){
				this.onModalClose(true);
			}
            else{
                stateJson.blocking = false;
                stateJson.hasModalerror = true;
                stateJson.Modalerrors = response.data;	
                this.setState( stateJson );
            }
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            stateJson.blocking=false;
            stateJson.errors='There was an error!' + error.message;
            this.setState( stateJson );
        });
    }


	onModalClose =(reload: boolean) =>{
		var stateJson = {
			blocking:false,
            showModal: false,
			selectedvcffile: this.state.selectedvcffile
        } as ClusteringState;

		if(reload){
			this.loadSampleList(stateJson);
		}
		else{
			this.setState (stateJson);
		}
    }

	onEnterModalListName = (event: React.ChangeEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
		this.setState({listname: target.value});
	}

    OnhandleselectModalGroupRows =(selectedRows:[]) =>{   
        this.selectedModalGroups = selectedRows;
    }

	onSelectedPointes =(ids: string[]) => {
		var selectedIds = this.state.selectedDualListSampleids;
		for (var i = 0; i < ids.length; i++){
			var id = ids[i];
			if(!selectedIds.includes(id)){  //add to list
				selectedIds.push(id);
			}
			else{   //remove from list
				selectedIds = selectedIds.filter(x=>x !== id);
			}
		}

		var flag = this.state.refreshflag + 1;
		this.setState({selectedDualListSampleids: selectedIds, refreshflag: flag});
	}

	onSelectedDualListSampleIds =(value: string[]) =>{
		var flag = this.state.refreshflag + 1;
		this.setState({selectedDualListSampleids: value, refreshflag: flag});
	}

	getVisualizationResults =(cohortdata:string) => {
		var stateJson = {
			blocking:true,
			blockmessage:"Loading ... ",
			resultfound: false,
			metadataChanged: false,
			errors:""
		} as ClusteringState;

		this.setState(stateJson);

		var selectedVCFfile = JSON.parse(this.state.selectedvcffile) as VCF;

		let url = API_URL +'pca/Clusting';
        axios.post(url, {
			"samplelist": cohortdata, 
			"vcffile": this.selectedVCFfilename, 
			"hasmetadata": selectedVCFfile.hasmetadata })
            .then(response => {
				stateJson.blocking = false;
				if(response.data.Results.length > 0){
					stateJson.resultfound = true;
					this.clusteringResult = this.PrepareData(response.data.Results, stateJson);

					this.colorTypes = response.data.Metadata;
					stateJson.colorDD = Object.keys(this.colorTypes)[0];
					stateJson.metadataChanged=true;
				}
				else{
					stateJson.errors = "No clusting data.";
				}
				this.setState(stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                stateJson.blocking = false;
                stateJson.errors = error.message;
                this.setState(stateJson);
            });
	}

	PrepareData=(data :  Array<PlinkResult>, stateJson: ClusteringState) => {
		var newdata = [];
		var sampleIds = Array<SearchableListData>();
		for (var i = 0; i < data.length; i++) {
			var olddata = (data[i] as PlinkResult).rowdata;
			newdata.push(olddata);
			var id = olddata.IID as string;
			sampleIds.push({value: id, label: id});
		};

		stateJson.totalSampleids = sampleIds;
		stateJson.selectedDualListSampleids = [];
		return newdata;
	}

	componentDidMount() {
		this.loadvcffile();
	}

    updateScreen(){
        removeOrcidCode("Clustering", this.props);
		var newsession = JSON.parse(sessionStorage.userSession);
		if(this.usersession.email != "anonymous" && newsession.email === "anonymous" ) { //logout
			this.usersession = newsession;
			this.colorTypes = {};
			this.loadvcffile();
		}
		else{
			this.usersession = newsession;
			this.cohorts = this.usersession.Depot_cohorts;
		
			if(this.usersession.isverified){
				this.verified_listclass="col-md-10";
				this.all_listClass="col-md-1 allClass";
				this.sample_listClass="col-md-4";
			}
			else{
				this.verified_listclass="col-md-6";
				this.all_listClass="col-md-3 allClass";
				this.sample_listClass="col-md-6";
			}
		}
	}

	render() {
        this.updateScreen();
		return (
			<div>
				<BlockUi tag="div" blocking={this.state.blocking} loader={<DNA visible={true} height="80" width="80" ariaLabel="DNA-loading" wrapperStyle={{}} wrapperClass="DNA-wrapper"/>}>
					<div className="gap-page-content">
						<div className="container">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className='breadcrumb-item'>
										<UISref to="home"><a>Home</a></UISref>
									</li>
									<li className='breadcrumb-item'>
										<UISref to="variants"><a>Variant</a></UISref>
									</li>
									<li className="breadcrumb-item active" aria-current="page">Clustering</li>
								</ol>
							</nav>

							<form name="vcfForm">
								<div className="row">
									<div className="col-md-8">
										<label htmlFor="vcffile">VCF File</label>
										<div className="form-group">
											<select
												className="form-select"
												value={this.state.selectedvcffile}
												onChange={this.onChangevcf}>
												{
													Object.entries(this.vcffiles).map(([key, value], i) => (
														<option key={key} value={JSON.stringify(value)}>{value.displayname}</option>
													))
												}
											</select>
										</div>
									</div>
									{ Object.keys(this.colorTypes).length >0 &&
										<div className="col-md-3">
											<label htmlFor="colorDD">Color By Metadata</label>
											<select
											    id ="colorDD"
                                                className="form-select"
                                                value={this.state.colorDD}
                                                onChange={this.onChangeColor}>
                                                {
                                                    Object.entries(this.colorTypes).map(([key, value], i) => (
                                                        <option key={key} value={key}>{key}</option>
                                                    ))
                                                }
                                            </select>
										</div>
									}
								</div>
								<div className="row margin-top-10">
									<span><label>Samples</label></span>
								</div>
								<fieldset className="custom_fieldset  col-md-8"  >
									<div className="row margin-top-15 center-content" >
										<div className="col-md-2">
											<input id="cb_upload" type="radio" name="ssridtype" value="a" checked={this.state.ssridtype === "a"} onChange={this.onSsridtypeChange} />
											<span> <label htmlFor="cb_upload" >All samples</label></span>
										</div>

										<div className="col-md-10">
											<div className="row center-content">
												<div className="col-md-2 group-content">
													<input id="cb_upload" type="radio" name="ssridtype" value="s" checked={this.state.ssridtype === "s"} onChange={this.onSsridtypeChange} />
													<span> <label htmlFor="cb_upload" >&nbsp;Sample List</label></span>
												</div>

												<div className="col-md-10">
													<select
														id="gcontrols"
														className="form-select list"
														value={this.state.selectedSamplelist}
														disabled={this.state.ssridtype != 's'}
														onChange={this.onChangeList}>
														<option value="">-- please choose a sample list --</option>
														{
															Object.entries(this.sampleLists).map(([key, value], i) => (
																<option key={key} value={JSON.stringify(value)}>{value.listname}</option>
															))
														}
													</select>
												</div>
											</div>
										</div>

									</div>
								</fieldset>
				
								<div className="row">
									<div className="col-md-6">
										<button id="search" type="button" onClick={this.OnSearchData}
											className=" btn btn-primary" 
											disabled={(this.state.ssridtype == 's' && this.state.selectedSamplelist == '') ||
													  (this.state.ssridtype == 'c' && this.state.selectedCohort == '')  || 
													  (this.state.ssridtype == '' )
													 }>Submit
										</button>
									</div>
								</div>

								<div className="row">
                                	<span className="redcolor">{this.state.errors}</span>
                            	</div>

								{this.state.resultfound && 
									<div className="row margin-top-20">
										<hr></hr>
										{Object.keys(this.colorTypes).length >0 && 
											<div className="row">
												<div className="col-md-2">
													<input id="cb_Name" type="radio" name="sortlegend_n" value="n" checked={this.state.sortlegend === "n"} onChange={this.onSortLegndChange} />
													<span> <label htmlFor="cb_Name" >Sort by legend name</label></span>
												</div>

												<div className="col-md-3">
													<input id="cb_Frequency" type="radio" name="sortlegend_f" value="f" checked={this.state.sortlegend === "f"} onChange={this.onSortLegndChange} />
													<span> <label htmlFor="cb_Frequency" >Sort by legend frequency</label></span>
												</div>
												<br></br>
												<br></br>
											</div>
										}
										<Plotly 
											refreshflag = {this.state.refreshflag}
											clusteringResult={this.clusteringResult} 
											selectedPointes={(ids: string[]) => this.onSelectedPointes(ids)} 
											metadata={this.colorTypes[this.state.colorDD]} 
											metadatatype={this.state.colorDD} 
											metadataChanged={this.state.metadataChanged} 
											legnedsort = {this.state.sortlegend}
											pointsSeletced = {this.state.selectedDualListSampleids} />
										
										<div>
											<div className="row">
												<div className="col-md-6">
													<label className="rdl-control-label">Available Samples ({this.state.totalSampleids.length - this.state.selectedDualListSampleids.length}) </label>
												</div>
												<div className="col-md-6">
													<label className="margin-left-10 rdl-control-label">Selected Samples ({this.state.selectedDualListSampleids.length}) </label>
												</div>	
												<DualListBox
													canFilter
													showHeaderLabels={false}
													options={this.state.totalSampleids}
													selected={this.state.selectedDualListSampleids}
													onChange={(value) => this.onSelectedDualListSampleIds(value)}
												/>

											</div>
											{this.usersession.isverified &&
												<div className="row margin-top-10">
													<div className="col-md-6">
														<button id="createlist" type="button" onClick={this.OnCreateList}
															disabled={this.state.selectedDualListSampleids.length == 0}
															className=" btn btn-primary" >Create Sample List
														</button>
														{/* &nbsp;&nbsp;
															<button id="creatCohort" type="button" onClick={this.OnCreateCohort}
																disabled={this.state.selectedDualListSampleids.length == 0}
																className=" btn btn-primary">Create Cohort
															</button> */}
													</div>
												</div>
											}
										</div>
									</div>
								}
							</form>
						</div>
					</div>

					<Modal show={this.state.showModal}
						size="lg"
						animation={false}
						backdrop="static"
						keyboard={false}>
						<Modal.Header>
							<Modal.Title>
								<div>
									<h2 className="lead intro">Create a List </h2>
									{/* {this.state.islistmodal
										? <h2 className="lead intro">Create a List </h2>
										: <h2 className="lead intro">Create a Cohort </h2>
									} */}
								</div>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{this.state.hasModalerror &&
								<div className="row redcolor">
									<p>{this.state.Modalerrors}</p>
								</div>
							}
							<div className="row col-md-6">
								<div className="form-group">
									<label htmlFor="listName">Create a List</label>
									<input type="text"
										className="form-control" name="listName" id="listName"
										value={this.state.listname}
										onChange={this.onEnterModalListName}
										placeholder="List Name" required />
								</div>
							</div>
							
							<div className="row">
								<div className="form-group margin-top-10">
									<label htmlFor="vcfFile">User Groups</label>
									<GroupTable_MUI
										searchResults={this.allGroups}
										ToggledMenu={true}
										checkboxSelection={false}
										OnSelect={(selectedRows: []) => this.OnhandleselectModalGroupRows(selectedRows)}
									/>

									{/* <UserGroupTable
										searchResults={this.allGroups}
										IsGroupTable={true}
										IsListGroup={true}
										IsSelectTable={true}
										HideSelectColumn={false}
										OnSelect={(selectedRows: []) => this.OnhandleselectModalGroupRows(selectedRows)}
									/> */}
								</div>
							</div>

						</Modal.Body>
						<Modal.Footer>
							<button type="button" className="btn btn-primary" disabled={this.state.listname === ""} onClick={this.onModalSave} >Save</button>
							<button className="btn btn-outline-secondary" onClick={() => this.onModalClose(false)}> Close </button>
						</Modal.Footer>
					</Modal>
				</BlockUi>
			</div>
		);
    }
  }