
import React from "react";
import BlockUi from '@availity/block-ui';
import { DNA } from  'react-loader-spinner';
import { ProgressBar } from 'react-bootstrap';
import axios from "axios";
import UserSession from '../../services/UserSession';

import { UISref, Transition} from "@uirouter/react";
import Select, {SingleValue, createFilter} from 'react-select';
import API_URL from '../../services/Enviroment';

type uploadState = {
    blocking: boolean,
    blockmessage: string,
    uploadFile?: File,
    progress1: number,
    haserror: boolean,
    errors: string
}

export default class UserMetadata extends React.Component <Transition, uploadState> {
    usersession = UserSession.getUserSession();

    constructor(props: Transition) {
        super(props);
        
        this.state = {
            blocking: false,
            blockmessage: "Loading",
            uploadFile: undefined,
            progress1:0,
            haserror: false,
            errors: ""
        };
    }

    onUploadFileChange = (event: React.ChangeEvent <HTMLInputElement>) => {
        const fileList = event.target.files;
        if (!fileList) return;
        this.setState({uploadFile: fileList[0]})
    }

    uploadfiles =() =>{
        if(!(this.state.uploadFile?.name.endsWith(".txt"))) {
            alert("Please select text file to upload.");
            return
        }

        var stateJson = {
            blocking: true,
            blockmessage: "Upload... ",
            haserror : false,
            errors: ""
        } as uploadState;
        this.setState(stateJson);

        let url = API_URL + 'admin/UploadMetadata';
        let formData = new FormData()
        formData.append("file", this.state.uploadFile);
        axios.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: data => {
                if(data.total){
                    this.setState({ progress1: (Math.round((100 * data.loaded) / data.total)) });
                }
            },
        })
        .then(response => {  //uploaded
            stateJson.blocking = false;
            stateJson.haserror = true;
            stateJson.errors = "Upload sucessfully.";
            this.setState(stateJson);
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            stateJson.blocking = false;
            stateJson.haserror = true;
            stateJson.errors = error.response.data;
            this.setState(stateJson);
        });
    }

    checkstatus =() =>{
        if (sessionStorage.token == null || sessionStorage.token == "") {
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }

        this.usersession = JSON.parse(sessionStorage.userSession);
        if(!this.usersession.isverified){
            let transition = this.props;
            const $state = transition.router.stateService;
            $state.go('home');
        }
    }
    
    render(){
        this.checkstatus();
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking} loader={<DNA visible={true} height="80" width="80" ariaLabel="DNA-loading" wrapperStyle={{}} wrapperClass="DNA-wrapper"/>}>
                    <div className="gap-page-content">
                        <div className="container">
                            <h1>User Metadata</h1>
                            <hr></hr>
                            {this.state.haserror &&
                                <div className="row redcolor">
                                    <p>{this.state.errors}</p>
                                </div>
                            }
                            <form name="uploadForm">
                                <div className="form-group margin-bottom-20">
                                    <label htmlFor="file">Upload Metadata File</label>
                                    <div className="col-md-6">
                                        <input id="file" type="file" onChange={this.onUploadFileChange} accept=".txt" />
                                        <ProgressBar className="margin-top-10" now={this.state.progress1} label={`${this.state.progress1}%`} />
                                    </div>
                                </div>
                                <button id="tbproilferFormdata" type="button" className="btn btn-primary"
                                    disabled={!this.state.uploadFile}
                                    onClick={this.uploadfiles}>Upload Metadata
                                </button>

                            </form>
                        </div>
                    </div>
                </BlockUi>
            </div>
        )
    };
};
