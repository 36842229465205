import React, { useEffect } from 'react';
import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp, useGridApiRef } from '@mui/x-data-grid';
import PredictionResult from '../../../domain/PredictionResult';

interface RPTableData {
  searchResults: Array<PredictionResult>;
  hascompare: boolean;
  resistanceType: string;
  deleteResult(id: string) : void;
  cancelProcessing(id: string) : void;
  comparereport?(selectedResult:Array<PredictionResult>) : void;
  fetchTextResult?(predictionid:String, version: String) : void;
}

export default function RPTable_MUI (props : RPTableData) {
  const apiRef = useGridApiRef();

  //const [showtbprofiler, setshowtbprofiler] = React.useState<boolean>(false);
  //const [TbProfileResults, setTbProfileResults] = React.useState<string>("");
  const [disabledcompare, setDisabledcompare] = React.useState<boolean>(true);
 
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [selectedRows, setSelectedRows] = 
    React.useState<GridRowsProp>([]);

  const RPresultColumns : GridColDef[] = [
    { field: 'fasq1', headerName: 'Fasq1', sortable: true,  flex: 1, width:100, },
    { field: 'fasq2', headerName: 'Fasq2', sortable: true, flex: 1, width:100},
    { field: 'predictionType', headerName: 'Prediction Type', sortable: true, width: 200},
    { field: 'submitdate', headerName: 'Submit Date', sortable: true, width: 200},
    { field: 'actions', type:"actions", headerName: 'Result', width: 300,
      renderCell: (params) => {
        if (showfailed(params.row.isdefault)) {
          return (
            <div>
              <span style={{ fontSize: "10px" }}>No Result&nbsp;&nbsp;</span>
              <button type="button" className="btn btn-primary"
                onClick={() => props.deleteResult(params.row)}>
                <span><i className="fa fa-trash"></i>&nbsp;&nbsp;</span>Delete
              </button>
            </div>
          )
        }
        else{
          return (
            <div>
              {isCanceling(params.row) &&
                <button type="button" className="btn btn-primary" disabled={true}>
                  <span><i className="fa fa-refresh fa-spin"></i>&nbsp;&nbsp;</span>Canceling
                </button>
              }
              {!isCanceling(params.row) &&
                <button type="button" className="btn btn-primary"
                  disabled={isProcessing(params.row)}
                  onClick={() => ShowResult(params.row)}>
                  {isProcessing(params.row) === false &&
                    <div>
                      <span><i className="fa fa-save"></i>&nbsp;&nbsp;</span>Result
                    </div>
                  }
                  {isProcessing(params.row) &&
                    <div>
                      <span><i className="fa fa-refresh fa-spin"></i>&nbsp;&nbsp;</span> Processing
                    </div>
                  }
                </button>
              }&nbsp;&nbsp;
              {isProcessing(params.row) && !isCanceling(params.row) &&
                <button type="button" className="btn btn-primary"
                  disabled={isCanceling(params.row)}
                  onClick={() => props.cancelProcessing(params.row.predictionid)}>
                  <span><i className="fa fa-trash"></i>&nbsp;&nbsp;</span>Cancel
                </button>
              }
              {!isProcessing(params.row) && !isCanceling(params.row) &&
                <button type="button" className="btn btn-primary"
                  onClick={() => props.deleteResult(params.row.predictionid)}>
                  <span><i className="fa fa-trash"></i>&nbsp;&nbsp;</span>Delete
                </button>
              }
            </div>
          )
        }
      }
    }, 
  ];

  const showfailed =(rowdata: PredictionResult) =>{
    
		if(rowdata != undefined && rowdata.status === "failed"){
				return true;
		}

		return false;
  }

  const isProcessing=(rowdata: PredictionResult)=>{
		if(rowdata.status === "completed"){
			return false;
		}
		else
			return true;
  }

  const isCanceling=(rowdata: PredictionResult)=>{
		if(rowdata.status === "cancelled"){
			return true;
		}
		else
			return false;
  }

  const ShowResult=(rowdata:PredictionResult)=>{
    var version = rowdata.predictionVersion;
    if(props.fetchTextResult)  props.fetchTextResult( rowdata.predictionid, version );
  }

  const comparereport=()=>{  
    props.searchResults.map(function (result, i){
      if(selectedRows.find(x=>x.predictionid == result.predictionid))
          result.compared = true;
      else{
        result.compared = false;
      }
    }); 

    if(props.comparereport) props.comparereport(props.searchResults);
  }

  useEffect(() => {

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });

  }, []);

    return (
      <div className='row'>
        <div style={{ width: '100%' }}>
					<label className="displaylable">Submitted Jobs</label>
          {props.hascompare && 
            <button  className="btn btn-primary comparebutton" disabled={disabledcompare} 
              onClick={comparereport}>
              Compare
            </button>
          }
				</div>
       
        <div>
          <DataGrid
            checkboxSelection={props.hascompare}
            rows={props.searchResults}
            columns={RPresultColumns}
            apiRef={apiRef}

            getRowId={(row) => row.predictionid}
            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}

            slotProps={{
              pagination: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}

            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 25, 50, 100]}

            onRowSelectionModelChange={(e) => {
              setRowSelectionModel(e);
              const selectedIDs = new Set(e);
              const totalSelectedRows = props.searchResults.filter((r) => selectedIDs.has(r.predictionid));
              var compareddisabled = true;
              if(totalSelectedRows.length > 1) compareddisabled = false;
              setSelectedRows(totalSelectedRows);
              setDisabledcompare(compareddisabled);
            }}
            rowSelectionModel={rowSelectionModel}
            
            sx={{ ".MuiDataGrid-cell": { p: 1 } }}
          />
        </div>
      
    </div>
    );
};