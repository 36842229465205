import { GridRowId } from "@mui/x-data-grid";
import { Transition } from "@uirouter/react";
import queryString from 'query-string';


export const  calculateDataGridPage = (datasource : GridRowId[], currentrowid: string, pagesize: number) => {
  var selectedpage = 0;
  if(datasource === undefined || currentrowid === "") return selectedpage;

  var pos = 0;
  for (var i = 0; i < datasource.length; i++) {
    if (datasource[i] === currentrowid) {
      selectedpage = parseInt((pos/pagesize).toString());
      break;
    } else {
      pos++;
    }
  }
  return selectedpage;
}

