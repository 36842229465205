import React, { useEffect } from 'react';
import $ from 'jquery'
import { DataGrid, GridColDef, GridRowsProp, useGridApiRef } from '@mui/x-data-grid';
import DistanceDetailDiff from '../../../domain/DistanceDetailDiff';

var select_id = "";
var select_id2 = "";

interface DistanceDiffTableData {
  Results: GridRowsProp;
}

export default function DistanceDiffTable_MUI (props : DistanceDiffTableData) {
  const apiRef = useGridApiRef();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const ResultColumns: GridColDef[] = [
    { 
      field: 'POS', 
      headerName: 'POS', 
      sortable: true,  
      width: 100
    }, 
    { field: 'REF', 
      headerName: 'REF', 
      sortable: true, 
      width: 150
    }, 
    { 
      field: 'ALT', 
      headerName: 'ALT', 
      sortable: true,
      width: 100
    },  
    { 
      field: 'GT1', 
      headerName: 'GT1', 
      sortable: true, 
      width:100
    }, 
    { 
      field: 'GT2', 
      headerName: 'GT2', 
      sortable: true, headerAlign: 'center', 
      width:100
    }, 
    { 
      field: 'Impact', 
      headerName: 'Impact', 
      sortable: true, 
      width:160
    }, 
    { 
      field: 'AAChange', 
      headerName: 'AA Change', 
      sortable: true, 
      width:100
    }, 
    { 
      field: 'GeneID', 
      headerName: 'Gene ID', 
      sortable: true, 
      width:100,
      renderCell: (params) => 
        <a className="link-primary" onClick={() => {popup_multipHyperlink(params.row.GeneID, params.row.RefseqID,'Gene');}}>{params.row.GeneID}</a>,
    },
    { 
      field: 'ProteinID', 
      headerName: 'Protein ID', 
      sortable: true, 
      width:120,
      renderCell: (params) => 
        <a className="link-primary" onClick={() => {popup_multipHyperlink(params.row.ProteinID, '', 'Protein');}}>{params.row.ProteinID}</a>,
    }, 
    { 
      field: 'ProteinName', 
      headerName: 'Protein Name', 
      sortable: true, 
      width: 200,
      flex: 1
    }, 
  ];
 
  const popup_multipHyperlink = function(g_id:string, id2:string, grouptype:string){
		select_id2="";
		select_id="";
		
		$(".custom-menu").finish().toggle(100).css({
		        top: (window.event as MouseEvent).pageY + "px",
		        left: (window.event as MouseEvent).pageX + "px"
		  }); // In the right position (the mouse)
		
		$('.custom-menu li').hide();
		$('.custom-menu li').filter('[data-label=' + grouptype + ']').show();
	
		if(id2 != null){
			select_id2 = id2.split("-")[0];
		}
		var first_id = g_id.split("-");
	  select_id = first_id[0];
	}

  const gotoextern = function(target: string){
		switch (target) {
			case "ncbi_gene":
				window.open("https://www.ncbi.nlm.nih.gov/gene?cmd=search&term=" + select_id, "_blank");
				break;
			case "geo":
				window.open("https://www.ncbi.nlm.nih.gov/geoprofiles?term=" + select_id, "_blank");
				break;
			case "kegg":
				window.open("https://www.genome.jp/dbget-bin/www_bfind_sub?dbkey=genes&keywords=" + select_id + "&mode=bfind&max_hit=100", "_blank");
				break;
			case "ncbi_protein":
				window.open("https://www.ncbi.nlm.nih.gov/protein/" + select_id, "_blank");
				break;
			case "uniprot":
				window.open("https://www.uniprot.org/uniprot/?query=" + select_id, "_blank");
				break;
			case "tbrnat":
				window.open("https://bioinformatics.niaid.nih.gov/tbrnat/?term=" + select_id2, "_blank");
				break;
        default:
          break;
		}
	}

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          const resizeObserverErrDiv = document.getElementById(
              'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
              'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
              resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
              resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
      }
  });

    return function() {
      document.addEventListener('click', handleClickOutside, true);
    }
  }, []);
  
  const handleClickOutside = () => {
    $(".custom-menu li").hide();
    $(".custom-menu").hide();
  };

  return (
      <div>
        <div style={{ width: '100%' }}>
          <DataGrid 
            rows={props.Results} 
            columns={ResultColumns}  
            apiRef={apiRef}
            getRowId = {(row)=>row.POS}

            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}

            initialState={{
                sorting: {
                  sortModel: [{ field: 'POS', sort: 'asc' }],
                },
              }}

            slotProps={{
                pagination: {
                  showFirstButton: true,
                  showLastButton: true,
                },
            }}

            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 25, 50, 100]}

            sx={{ ".MuiDataGrid-cell": {p: 1} }}
          />
        </div>
            
        <div>
          <ul className='custom-menu'>
              <li data-label="Gene"><a className="link-primary" onClick={() => gotoextern('ncbi_gene')} >NCBI Gene Database</a></li>
              <li data-label="Gene"><a className="link-primary" onClick={() => gotoextern('kegg')} >KEGG Genes Database</a></li>
              <li data-label="Gene"><a className="link-primary" onClick={() => gotoextern('tbrnat')} >TBRNAT</a></li>
              <li data-label="Protein"><a className="link-primary" onClick={() => gotoextern('ncbi_protein')} >NCBI Protein</a></li>
              <li data-label="Protein"><a className="link-primary" onClick={() => gotoextern('uniprot')} >UniProt</a></li>
          </ul>
        </div>
    </div>
  );
};