import * as React from "react";
import { UISref, Transition, isNullOrUndefined } from "@uirouter/react";
import { ProgressBar } from 'react-bootstrap';
import BlockUi from '@availity/block-ui';
import { DNA } from  'react-loader-spinner';
import queryString from 'query-string';
import axios from "axios";
import API_URL from '../../../services/Enviroment';
import UserSession from "../../../services/UserSession";
import PredictionResult from '../../../domain/PredictionResult';
import { CSVLink } from "react-csv";
import {removeOrcidCode} from '../../utils/gapCommon';
import NCBISampleInfor from "../../../domain/NCBISampleInfor";
import ENASampleInfor from "../../../domain/ENASampleInfor";
import './ResistancePrediction.css'
import RPTable_MUI from "./RPTable_MUI";

let usersession = UserSession.getUserSession();
type predictionType ={
  TBProfiler: boolean,
  //NTMProfiler: boolean,
  KvarQ: boolean,
  myKrobe: boolean
}

type csvHeadType = {
  label: string,
  key: string,
}

type RPState = {
  blocking: boolean,
  errors: string,
  fastq1File?: File,
  fastq2File?: File,
  predictionTypes: predictionType,
  samplePlaceholder:string,
  progress1: number,
  progress2: number,
  hascomparedresult: boolean,
  haspredictionresult: boolean,
  TbSessions: Array<PredictionResult>;
  fastqtype: string,
  ncbisample: string,
}

export default class TBResistancePrediction extends React.Component<Transition, RPState> {
  usersession = UserSession.getUserSession();
  timer : NodeJS.Timeout | undefined = undefined;
  comparedcols = Array<csvHeadType>();
  comparedrows = Array<Array<string>>();
  DownloadData = Array();
  PredictionResults = "";

  ncbiSamplesInfo = Array<NCBISampleInfor>();
  enaSamplesInfo = Array<ENASampleInfor>();
  ncbiSampleplaceholder = "Input NCBI sample ID, start with SRR. Separated by ',' for multiple sample Ids";
  enaSampleplaceholder= "Input ENA sample ID, start with DRR. Separated by ',' for multiple sample Ids";
  
  constructor(props: Transition) {
    super(props);
   
    this.state = {
      blocking: false,
      fastq1File: undefined,
      fastq2File: undefined,
      predictionTypes: {TBProfiler: false, KvarQ: false, myKrobe: false},
      // predictionTypes: {TBProfiler: false, NTMProfiler: false, KvarQ: false, myKrobe: false},
      progress1:0,
      progress2:0,
      hascomparedresult: false,
      haspredictionresult: false,
      samplePlaceholder:"",
      errors: "",
      TbSessions: [],
      fastqtype: "l",
      ncbisample: ""
    };

    //  if (sessionStorage.token == null || sessionStorage.token == "") {
    //     let transition = this.props;
    //     const $state = transition.router.stateService;
    //     $state.go('home');
    // }
  
    // this.usersession = JSON.parse(sessionStorage.userSession);
    // if(!this.usersession.isverified){
    //     let transition = this.props;
    //     const $state = transition.router.stateService;
    //     $state.go('resistance');
    // } 

  }

  onFastq1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    var fileList = event.target.files;
    if (!fileList) return;
    this.setState ({fastq1File: fileList[0]});
}

  onFastq2Change = (event: React.ChangeEvent <HTMLInputElement>) => {
      const fileList = event.target.files;
      if (!fileList) return;
      this.setState({fastq2File: fileList[0]})
  }


  onTBProfilerChange =  (event: React.FormEvent <HTMLInputElement>) => {
    var predictionTypes = this.state.predictionTypes;
    predictionTypes.TBProfiler = !this.state.predictionTypes.TBProfiler;
    this.setState({predictionTypes: predictionTypes});
  }

  onKvarQChange =  (event: React.FormEvent <HTMLInputElement>) => {
    var predictionTypes = this.state.predictionTypes;
    predictionTypes.KvarQ = !this.state.predictionTypes.KvarQ;
    this.setState({predictionTypes: predictionTypes});
  }

  onKrobeChange =  (event: React.FormEvent <HTMLInputElement>) => {
    var predictionTypes = this.state.predictionTypes;
    predictionTypes.myKrobe = !this.state.predictionTypes.myKrobe;
    this.setState({predictionTypes: predictionTypes});
  }

  excuteTBCommand = () => {
    if (this.state.fastqtype === "l" ){
      if(this.checkfilename(this.state.fastq1File?.name, this.state.fastq2File?.name) == false) {
        alert("Please select fastq or fastq.gz file and matching files for forward and reverse reads.");
        return
      }
    }

    this.ncbiSamplesInfo = new Array<NCBISampleInfor>();
    this.enaSamplesInfo = new Array<ENASampleInfor>();
    
    this.setState({ errors: ""});

    let url = API_URL + 'prediction/checkWorkerLimitation';
    axios.get(url)
        .then(response => {
          if (this.state.fastqtype === "l" ){
            this.uploadlocalfile();
          }
          else if (this.state.fastqtype === "n" ){
            this.processNCBISample();
          }
          else if (this.state.fastqtype === "e" ){
            this.processENASample();
          }
        })
        .catch(error => {
          console.error('There was an error!' + error.message);
          this.setState({ errors: error.response.data});
        });
  }

  processENASample =() => {
    var stateJson = {
      blocking: true,
      errors:""
    } as RPState;

    this.setState(stateJson);

    let url = API_URL + 'prediction/processENASample';
    axios.post(url, { "sampleID": this.state.ncbisample, "PredictionType": this.state.predictionTypes})
    .then(response => {
      this.enaSamplesInfo = response.data;
      stateJson.blocking=false;
      this.loadResults();
      this.setState(stateJson);
    })
    .catch(error => {
      stateJson.blocking = false;
      this.enaSamplesInfo = new Array<ENASampleInfor>(); ;
      if(error.response.status == 400){
        stateJson.errors = error.response.data;
        this.setState(stateJson);
      }
      else {
        stateJson.errors = "Fetch NCBI Fastq file failed, Error code:" + error.response.status  + ". Error:" + error;
        this.setState(stateJson);
      }
      
    });
  }

  processNCBISample =() => {
    var stateJson = {
      blocking: true,
      errors:""
    } as RPState;

    this.setState(stateJson);

    let url = API_URL + 'prediction/processNCBISample';
    axios.post(url, { "sampleID": this.state.ncbisample, "PredictionType": this.state.predictionTypes})
    .then(response => {
      this.ncbiSamplesInfo = response.data;
      stateJson.blocking=false;
      this.setState(stateJson);
      this.loadResults();
    })
    .catch(error => {
      stateJson.blocking = false;
      this.ncbiSamplesInfo = new Array<NCBISampleInfor>();
      if(error.response.status == 400){
        stateJson.errors = error.response.data;
        this.setState(stateJson);
      }
      else {
        stateJson.errors = "Fetch NCBI Fastq file failed, Error code:" + error.response.status  + ". Error:" + error;
        this.setState(stateJson);
      }
      //this.setState(stateJson);
      //console.error('There was an error!' + error.message);
    });
  }

  uploadlocalfile=()=>{
    if (this.state.fastq1File == undefined) return;
    var uploadfile1 = this.state.fastq1File;

    this.setState({progress1:0, progress2:0});

    let url = API_URL + 'prediction/uploadFastqFile';
    let formData = new FormData()
    formData.append("file", uploadfile1);
    axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        if(data.total){
          this.setState({ progress1: (Math.round((100 * data.loaded) / data.total)) });
        }
      },
    })
      .then(response => {
        var uploadfilename1 = response.data.filename;
        var foldername = response.data.foldername;
        this.uploadfile2(uploadfilename1, foldername);
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
        this.setState({ errors: error.response.data});
      });
  }

  uploadfile2 = (uploadfilename1: string, foldername:string ) => {
    if (this.state.fastq2File == undefined) return;
    var uploadfile2 = this.state.fastq2File;
    let url = API_URL + 'prediction/uploadFastqFile';
    let formData = new FormData()
    formData.append("file", uploadfile2);
    formData.append("foldername", foldername);
    axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        if(data.total){
          this.setState({ progress2: (Math.round((100 * data.loaded) / data.total)) });
        }
      },
    })
    .then(response => {  //uploaded
      var uploadfilename2 = response.data.filename;
      this.getPredictionResults(uploadfilename1, uploadfilename2, foldername);
    })
    .catch(error => {
        console.error('There was an error!' + error.message);
        this.setState({ errors: 'There was an error!' + error.message });
    });
  }

  getPredictionResults = (fastq1: string, fastq2: string, foldername:string) => {
    let url = API_URL + 'prediction/PredictionResult';
    axios.post(url, { "fastq1": fastq1, "fastq2": fastq2, "PredictionType": this.state.predictionTypes, "foldername":  foldername})
      .then(response => {
        this.loadResults();
      })
      .catch(error => {
        this.setState({ errors: error.response.data});
        console.error('There was an error!' + error.message);
      });
  }

  loadResults =() =>{
    this.stopInterval();

    let url = API_URL + 'prediction/LoadResults';
    axios.post(url, {"predictionSession":this.state.TbSessions})
        .then(response => {
          if(response.data != null){
            var tbsession = response.data.filter(function(data : PredictionResult){
        			return (data.predictionType == 'TBProfiler' || data.predictionType == 'myKrobe');
        		});
            this.setState({TbSessions: tbsession})
            this.checkresult(tbsession);
          }
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            this.setState({ errors: 'There was an error!' + error.message, blocking: false });
        }); 	
  }

  checkresult = (tbSessions: Array<PredictionResult>) =>{
		if(tbSessions.length > 0){
			var hasresult = true; 

      tbSessions.forEach((item) => {
				 if(item.status === "submitted" || item.status === "processing" || item.status === "cancelled"){
					 hasresult = false;
				 }
			 });
			 
			 if(hasresult && this.timer != undefined){
				 this.stopInterval();
			 }
		
			if(!hasresult && this.timer == undefined){
        	this.startInterval();
			}
		}
	}

  deleteResult = (id:String) =>{
    let url = API_URL + 'prediction/deleteResult';
    axios.post(url, {"predictionId":id})
        .then(response => {
          var tbsession = this.state.TbSessions;
          var newtbsession = tbsession.filter(function(data : PredictionResult){
            return (data.predictionid !== id);
          });

          this.setState({TbSessions: newtbsession})

          this.loadResults();
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            this.setState({ errors: 'There was an error!' + error.message, blocking: false });
        }); 	
  }

  cancelProcessing =(id:String) =>{
    let url = API_URL + 'prediction/cancelProcessing';
    axios.post(url, {"predictionId":id})
        .then(response => {
          var tbsession = this.state.TbSessions;
          var newtbsession = tbsession.filter(function(data : PredictionResult){
            return (data.predictionid !== id);
          });

          this.setState({TbSessions: newtbsession})
          
          this.loadResults();
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            this.setState({ errors: 'There was an error!' + error.message, blocking: false });
        }); 	
  }

  fetchTextResult = (predictionid : String, version:String)=>{
    var stateJson = {
      blocking: true,
      hascomparedresult: false,
      errors:""
    } as RPState;

    this.setState(stateJson);

    let url = API_URL + 'prediction/fetchPredictionTextResult';
    axios.post(url, {"predictionId":predictionid})
        .then(response => {
          if(response.data != null){
          this.PredictionResults = version + "\n" + response.data;
          }
          stateJson.blocking = false;
          stateJson.haspredictionresult = true;
          this.setState(stateJson);
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            this.setState({ errors: 'There was an error!' + error.message, blocking: false });
        }); 
  }

  comparereport =(selectedresult: Array<PredictionResult>) =>{	
		this.stopInterval();
    var stateJson = {
      blocking: true,
      hascomparedresult: false,
      errors:""
    } as RPState;

    this.setState(stateJson);
		
		this.comparedcols=[];
		this.comparedrows=[];
    this.DownloadData=[];

    let url = API_URL + 'prediction/compareresult';
   
    axios.post(url, { sessiondata: selectedresult })
      .then(response => {
        var comparedresult = response.data;
        if(comparedresult.row){
          stateJson.hascomparedresult=true;
          var cols = comparedresult.col as Array<string>;
          this.comparedcols = Object.entries(cols).map(([key, value], i) => {
            return { "label":value,  "key": "col" + key}
          });
          this.comparedrows=comparedresult.row
          var rows = comparedresult.row as Array<string>;
          this.DownloadData = Object.entries(rows).map(([ki, data], i) => {
            var rowJson: { [key: string]: string }={};
            Object.entries(data).map(([key1, value], i) => {
              rowJson['col' + key1] = value;
            });
            return rowJson;
          });
        }

        stateJson.blocking = false;
        this.setState(stateJson);

        this.checkresult(this.state.TbSessions);
      })
      .catch(error => {
        console.error('There was an error!' + error.message);
        this.setState ({errors: 'There was an error!' + error.message, blocking:false});

      });
  }

  onFastqtypeChange =  (event: React.FormEvent<HTMLInputElement>) => {
    var target = event.target as HTMLInputElement;
    var holderString = target.value === "n" ? this.ncbiSampleplaceholder:this.enaSampleplaceholder;
    this.ncbiSamplesInfo = new Array<NCBISampleInfor>();
    this.enaSamplesInfo = new Array<ENASampleInfor>();
    this.setState({fastqtype:target.value, samplePlaceholder: holderString, ncbisample: ""}); 
  }

  onEnterncbiSample = (event: React.FormEvent <HTMLInputElement>) => {
    var target = event.target as HTMLInputElement;
    this.setState ({ncbisample: target.value});
  } 

  checkfilename = (name1?: string, name2?: string) => {
    var valid = false;
    var diff1 = "";
    var diff2 = "";

    if (name1 == undefined || name2 == undefined) return false;

    if (name1.length == name2.length) {
      var count = 0;
      for (var i = 0; i < name1.length; i++) {
        if (name1[i] != name2[i]) {
          count++;
          diff1 = name1[i];
          diff2 = name2[i];
        }
      }

      if (count == 1) {
        valid = true;
      }
    }

    if (!(name1.endsWith("fastq") || name1.endsWith("fastq.gz")) ||
      !(name2.endsWith("fastq") || name2.endsWith("fastq.gz"))) {
      valid = false;
    }

    return valid;
  }

  startInterval =() =>{
		this.timer = setInterval(() => {
			if(this.state.TbSessions.length > 0){
				this.loadResults();
			}
		}, 4000);
	}
	
  stopInterval = () => {
    if (this.timer != undefined) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  componentWillUnmount(){
    this.stopInterval();
  }

  // updateScreen() {
  //   removeOrcidCode("tbresistancePrediction", this.props);
  //   this.usersession = JSON.parse(sessionStorage.userSession);
  // }

  componentDidMount(){
    this.loadResults();
  }

  checkstatus =() =>{
    const queries = queryString.parse(window.location.search);
    if(Object.keys(queries).length > 0){
      removeOrcidCode("tbresistancePrediction", this.props);
    }
    else{
      this.usersession = JSON.parse(sessionStorage.userSession);
      if(!this.usersession.isverified){
        let transition = this.props;
        const $state = transition.router.stateService;
        $state.go('resistance');
      } 
    }
  }


   downloadResult =() =>{
    var downloadfilename = "Resistance Prediction";
    var file;
  
      file = new Blob([this.PredictionResults], {
        type: "application/txt",
      });
      var fileURL = URL.createObjectURL(file);
      var a         = document.createElement('a');
      a.href        = fileURL; 
      a.target      = '_blank';
      a.download    = downloadfilename;
      document.body.appendChild(a);
      a.click();
  }

  render() {
    this.checkstatus();
    return (
      <BlockUi tag="div" blocking={this.state.blocking} loader={<DNA visible={true} height="80" width="80" ariaLabel="DNA-loading" wrapperStyle={{}} wrapperClass="DNA-wrapper"/>}>
        <div className="gap-page-content">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className='breadcrumb-item'>
                  <UISref to="home"><a>Home</a></UISref>
                </li>
                <li className='breadcrumb-item'>
                  <UISref to="resistance"><a>Resistance</a></UISref>
                </li>
                <li className="breadcrumb-item active" aria-current="page">TB Resistance Prediction</li>
              </ol>
            </nav>
            <h1>TB Resistance Prediction</h1>

            <div className="row">
              <span className="redcolor">{this.state.errors}</span>
            </div>

            <div>
              The algorithms listed below will predict sensitivity or resistance to anti-TB drugs using whole genome sequencing data from the TB pathogen.   Enter an SRA id or upload the fastq files containing the sequencing data for a sample and then select one or more algorithms to run.   Results from different algorithms can be compared in a consolidated report.
            </div>
            <hr />
            <form name="tbproilferForm">
              <div className="row margin-top-10" >
                <div className="col-md-6">
                  <input id="cb_local" type="radio" name="fastqtype" value="l" checked={this.state.fastqtype === "l"}
                    onChange={this.onFastqtypeChange} />
                  <span className="margin-left-10" >
                    <label htmlFor="cb_sample">Local Upload</label>
                  </span>
                </div>
                {this.usersession.isverified &&
                  <div className="col-md-6">
                    <input id="cb_ncbi" type="radio" name="fastqtype" value="n" checked={this.state.fastqtype === "n"}
                      onChange={this.onFastqtypeChange} />
                    <span className="margin-right-20 margin-left-5" >
                      <label htmlFor="cb_list">NCBI</label>
                    </span>
                    <input id="cb_ncbi" type="radio" name="fastqtype" value="e" checked={this.state.fastqtype === "e"}
                      onChange={this.onFastqtypeChange} />
                    <span className="margin-right-20 margin-left-5" >
                      <label htmlFor="cb_list">ENA</label>
                    </span>
                  </div>
                  
                }
              </div>
              <div className="row">
                <div className="col-md-6 ">
                  <fieldset className="custom_fieldset" style={{ "height": "250px" }} disabled={this.state.fastqtype != 'l'}>
                    <div className="form-group margin-top-10">
                      <label htmlFor="Fastq1">Fastq1</label>
                      <input type="file" className="form-control" onChange={this.onFastq1Change} accept=".gz" />
                      <p className="help-block">Fastq.gz file containing forward reads</p>
                      <ProgressBar now={this.state.progress1} label={`${this.state.progress1}%`} />
                    </div>

                    <div className="form-group margin-top-10">
                      <label htmlFor="Fastq2">Fastq2</label>
                      <input type="file" className="form-control" onChange={this.onFastq2Change} accept=".gz" />
                      <p className="help-block">Fastq.gz file containing reverse reads</p>
                      <ProgressBar now={this.state.progress2} label={`${this.state.progress2}%`} />
                    </div>
                  </fieldset>
                </div>

                <div className="col-md-6">
                  <fieldset className="custom_fieldset" style={{ "height": "250px" }} disabled={this.state.fastqtype === 'l'}>
                  <div className="form-group margin-top-10">
                      <label htmlFor="ncbisample">Sample ID</label>
                      <input type="text" className="form-control" id="ncbisample" name="input" value={this.state.ncbisample}
                        onChange={this.onEnterncbiSample}
                        placeholder={this.state.samplePlaceholder} width="60px" required
                      />
                    </div>
                    {this.ncbiSamplesInfo.length > 0 &&
                        <div className="ncbienainfo">
                          {this.ncbiSamplesInfo.map((info) =>(
                            <div className="margin-top-8">
                              <div className="col-md-10">
                                <label>acc:&nbsp;</label> {info.acc}
                              </div>
                              <div className="col-md-10">
                                <label>size:&nbsp;</label> {info.size}
                              </div>
                              <div className="col-md-10">
                                <label>SEQ:&nbsp;</label>{info.SEQ}
                              </div>
                              <div className="col-md-10">
                                <label>platf:&nbsp;</label>{info.platf}
                              </div>
                              <div>
                                <label>FMT:&nbsp;</label>{info.FMT}
                              </div>
                            </div>
                          ))}
                      </div>
                    }

                    {this.enaSamplesInfo.length > 0 &&
                        <div className="ncbienainfo">
                          {this.enaSamplesInfo.map((info) =>(
                            <div className="margin-top-8">
                               <div className="col-md-10">
                                <label>acc:&nbsp;</label> {info.accession}
                              </div>
                              <div className="col-md-10">
                                <label>title:&nbsp; </label>{info.title}
                              </div>
                              <div className="col-md-10">
                                <label>description:&nbsp; </label>{info.description}
                              </div>
                              <div className="col-md-10">
                                <label>dataType:&nbsp; </label>{info.dataType}
                              </div>
                              <div>
                                <label>status:&nbsp; </label>{info.statusDescription}
                              </div>
                              <div>
                                <label>fastq1 size:&nbsp; </label>{info.fastq1}
                              </div>
                              <div>
                                <label>fastq2 size:&nbsp; </label>{info.fastq2}
                              </div>
                          </div>
                          ))}
                      </div>
                    }
                    
                  </fieldset>
                </div>

              </div>
              <hr />
              <h1>Prediction Algorithm</h1>
              <table>
                <tbody>
                <tr>
                  <td width="20px">
                    <input
                      id="cb_TBProfiler"
                      type="checkbox"
                      name="cb_TBProfiler"
                      checked={this.state.predictionTypes.TBProfiler}
                      onChange={this.onTBProfilerChange}
                    />
                  </td>
                  <td width="80px">
                    <label htmlFor="cb_TBProfiler">TBProfiler</label>
                  </td>
                  <td>
                    <a href="https://github.com/jodyphelan/TBProfiler" target="_blank">https://github.com/jodyphelan/TBProfiler</a>
                  </td>
                  <td>
                    <a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=26019726" target="_blank">publication</a>
                  </td>
                </tr>
                <tr>
                  <td width="20px">
                    <input
                      id="cb_myKrobe"
                      type="checkbox"
                      name="cb_myKrobe"
                      checked={this.state.predictionTypes.myKrobe}
                      onChange={this.onKrobeChange}
                    />
                  </td>
                  <td width="80px">
                    <label htmlFor="cb_myKrobe">myKrobe</label>
                  </td>
                  <td >
                    <a href="https://github.com/iqbal-lab/Mykrobe-predictor" target="_blank">https://github.com/iqbal-lab/Mykrobe-predictor</a>
                  </td>
                  <td>
                    <a href="https://www.ncbi.nlm.nih.gov/pubmed/?term=26686880" target="_blank">publication</a>
                  </td>
                </tr>
                </tbody>
              </table>
              <br />
              <button id="tbproilferFormdata" type="button" className="btn btn-primary"
                disabled={!this.state.predictionTypes.KvarQ && !this.state.predictionTypes.myKrobe && !this.state.predictionTypes.TBProfiler ||
                  (this.state.fastqtype==="l" && (this.state.fastq1File === undefined || this.state.fastq2File === undefined)) ||
                  (this.state.fastqtype ==="n" && (this.state.ncbisample === "")) ||
                  (this.state.fastqtype ==="e" && (this.state.ncbisample === "")) }
                onClick={this.excuteTBCommand}>Execute</button>
            </form>
            <br></br>
            {this.state.TbSessions.length >0 &&
              <div>
                <hr></hr>
                <RPTable_MUI
                  searchResults={this.state.TbSessions} 
                  hascompare={true} 
                  resistanceType={"TB"} 
                  comparereport= {(selectedResult:Array<PredictionResult>) => this.comparereport(selectedResult)}
                  deleteResult = {(id:String) => this.deleteResult(id)}
                  fetchTextResult = {(predictionid:String, version:String) => this.fetchTextResult(predictionid, version)}
                  cancelProcessing = {(id:String) => this.cancelProcessing(id)}
                  />
              </div> 
            }
            {this.state.haspredictionresult &&
              <div className="row margin-top-15" >
                <div>
                  <button
                    className="btn btn-primary comparebutton "
                    onClick={this.downloadResult}>
                    <span className="fa fa-save"></span>&nbsp;&nbsp;Download
                  </button>
                </div>
                <pre className="preOut">
                  <code>{this.PredictionResults}</code>
                </pre>
              </div>
            }
            {this.state.hascomparedresult &&
              <div>
                <div className="margin-bottom-10">
                  <CSVLink data={this.DownloadData} className="btn btn-success"
                      filename={"ResistancePrediction.csv"}
                      headers={this.comparedcols} >
                     
                    <span className="fa fa-save"></span>&nbsp;&nbsp;Download
                  </CSVLink>
                </div>

                <div className="row">
                  <table className="comparedtable">
                    <thead>
                      <tr>
                        {this.comparedcols.map(col => (
                          <th className="comparedtable">
                            {col.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {this.comparedrows.map(row => (
                        <tr>
                          {row.map(rowcol => (
                            <td className="comparedtable">
                              {rowcol}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </div>

        </div>
      </BlockUi>
    );
  }
}
