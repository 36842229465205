import React, { ChangeEvent, useEffect } from 'react';
import * as BSIcon from "react-icons/bs"
import "./VCFManagement.css";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import VCF from '../../../domain/VCF';
import NCBIReference from '../../../domain/NCBIReference';

var selectedpage = 1;
var selectedrowid = [];

interface VCFFilesTableData {
  searchResults: GridRowsProp;
  isAdmin: boolean;
  referenceData: { [key: string]: NCBIReference };
  selectedrowid: string;
  onEditVCF(row: VCF) : void;
  onDeleteVCF(row: VCF) : void;
  onUpdateRowData(row: VCF) : void;
}

export default function VCFFilesTable_MUI (props : VCFFilesTableData) {
  const VCFFilesColumns : GridColDef[] = [
    { field: 'filename', headerName: 'VCF File', sortable: true, flex: 1, minWidth:100},
    { field: 'vcf_description', headerName: 'Description', sortable: false,  width:300},
    { field: 'reference', headerName: 'Reference', sortable: false, width: 160, 
      renderCell: (params) => {
          return (
            <div>
              <select
                className="form-select"
                value={params.row.reference}
                onChange={event => onReferenceChange(event, params.row)}>
                {Object.entries(props.referenceData).map(([key, value], i) => (
                  <option key={key} value={value.referenceName}>{value.referenceName}</option>
                ))}
              </select>    
            </div>
          );
        }
    },  
    { field: 'owner', headerName: 'Owner', sortable: true, width:120,},
    { field: 'groupStr', headerName: 'User Group', sortable: true, width:150,},
    { field: 'isdefault', headerName: 'Default', sortable: true, width:80, 
       renderCell: (params) => {
          if(params.row.isdefault){
            return(
              <div className="checkbox disabled">
                <label>
                  <input type="checkbox" checked={ params.row.isdefault } />
                </label>
              </div>
            )
          }
          else{
            return (
              <div className="checkbox">
                <label>
                  <input type="checkbox" checked={ params.row.isdefault } onChange={event => onDefaultChange(event, params.row)} />
                </label>
              </div>
            )
          }
        }
    }, 
    { field: 'hasmetadata', headerName: 'Support Depot', sortable: true, width: 120,
        renderCell: (params) => {
          return (
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={ params.row.hasmetadata } onChange={event => onMetadataChange(event, params.row)} />
              </label>
            </div>
          )
        }
    }, 
    { field: 'actions', headerName: 'Action', type: 'actions',
        renderCell: (params) => {
            return <div>
                    <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => props.onEditVCF(params.row)}>
                      <BSIcon.BsPencil className="margin-bottom-3"  title="Assign User Group" />
						      	</button>
						      	<button type="button" className="btn btn-danger btn-sm"  onClick={() => props.onDeleteVCF(params.row)}>
                      <BSIcon.BsTrashFill className="margin-bottom-3"  title="Delete VCF file" />
						       	</button>
            </div>
          }
    }
  ];

  const getFilterColumns = () => {
    if(props.isAdmin){
      return VCFFilesColumns;
    }
    else{
      return VCFFilesColumns
        .filter((column) => column.field !== "hasmetadata" && column.field !== "isdefault");
    }
  };

  const onReferenceChange =(event: React.FormEvent<HTMLSelectElement>, row: VCF) => {
    var target = event.target as HTMLSelectElement;
    if(target.value !== undefined){
       var new_value = target.value;
       var newRow = JSON.parse(JSON.stringify(row));
       newRow.reference = new_value;
       props.onUpdateRowData(newRow);
     }
   }

   const onDefaultChange =  (event: ChangeEvent<HTMLInputElement>, row: VCF) => {
    var target = event.target;
    var new_value = target.checked;
    var newRow = JSON.parse(JSON.stringify(row));
    newRow.isdefault = new_value;
    props.onUpdateRowData(newRow);
   }

  const onMetadataChange =(event: ChangeEvent<HTMLInputElement>, row: VCF) => {
    var target = event.target;
    var new_value = target.checked;
    var newRow = JSON.parse(JSON.stringify(row));
    newRow.hasmetadata = new_value;
    props.onUpdateRowData(newRow);
  }

  useEffect(() => {
   
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          const resizeObserverErrDiv = document.getElementById(
              'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
              'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
              resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
              resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
      }
    });

  }, []);
  
    return (
      <div>
        <div>
        <DataGrid
          rows={props.searchResults}
          columns={getFilterColumns()}
          getRowId={(row) => row.vcfid}

          getRowHeight={() => 'auto'}
          getEstimatedRowHeight={() => 200}

          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
          }}

          slotProps={{
            pagination: {
              showFirstButton: true,
              showLastButton: true,
            },
          }}

          pageSizeOptions={[10, 25, 50, 100]}
          sx={{ ".MuiDataGrid-cell": { p: 1 } }}
        />
        </div>
    </div>
    );
};