import React from 'react';
import Button from '@mui/material/Button';
import {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef,GridRowsProp, GridRowSelectionModel, useGridApiRef, GridRowId } from '@mui/x-data-grid';
import PlinkRowData from "../../../domain/PlinkRowData";
import {calculateDataGridPage} from '../../utils/gapCommonTsx';
import $ from 'jquery';
import './GWAS.css';

var select_id = "";
var select_id2 = "";

interface GWASTableData {
  plinkResults: GridRowsProp;
  assoc: String;
  selectedrowid: string;
  openGen(row: PlinkRowData) : void;
}

export default function GWASTable_MUI (props : GWASTableData) {
  const apiRef = useGridApiRef();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [selectedRows, setSelectedRows] = 
    React.useState<GridRowsProp>([]);

  const GWASResultColumns: GridColDef[] = [
    { field: 'P', headerName: 'P-Value', width: 100, sortable:true },
    { field: 'BP', 
      headerName: 'Position', 
      width: 100, 
      sortable:true,
      renderCell: (params) => 
        <a className="link-primary" onClick={() => {props.openGen(params.row);}}>{params.row.BP}</a>,
    },
    { 
      field: 'Impact', 
      headerName: 'Impact',
      width: 100, 
      sortable: true
    },  
    { 
      field: 'AAChange', 
      headerName: 'AA Change', 
      width: 100, 
      sortable: true, 
    }, 
    { 
      field: 'RefAllele', 
      headerName: 'Ref Allele', 
      sortable: true, 
      width: 100, 
    }, 
    { 
      field: 'AltAllele', 
      headerName: 'Alt Allele', 
      sortable: true, 
      width: 100, 
    }, 
    { 
      field: 'GeneName', 
      headerName: 'Gene Name', 
      sortable: true, 
      width: 120, 
    }, 
    { 
      field: 'GeneID', 
      headerName: 'Gene ID', 
      sortable: true, 
      width: 100, 
      renderCell: (params) => 
        <a className="link-primary" onClick={() => {popup_multipHyperlink(params.row.GeneID, params.row.RefseqID,'Gene');}}>{params.row.GeneID}</a>,
    }, 
    { 
      field: 'ProteinID', 
      headerName: 'Protein ID', 
      sortable: true, 
      width: 100, 
      renderCell: (params) => 
        <a className="link-primary" onClick={() => {popup_multipHyperlink(params.row.ProteinID,'','Protein');}}>{params.row.ProteinID}</a>,
    }, 
    { 
      field: 'ProteinName', 
      headerName: 'Protein Name', 
      sortable: true, 
      width: 150, 
    }, 
    { 
      field: 'Count', 
      headerName: 'Count Cases/ Controls', 
      sortable: true, 
      width: 150, 
      renderCell: (params) => {
          if(props.assoc === 'GLM'){
            return <div>{`${params.row.A1_CASE_CT}/${params.row.A1_CTRL_CT}`}</div>;
          }
          return <div>{`${params.row.C_A}/${params.row.C_U}`}</div>;
        }
    }
  ];

  const popup_multipHyperlink = function(g_id:string, id2:string, grouptype:string){
		select_id2="";
		select_id="";
		
		$(".custom-menu").finish().toggle(100).css({
      top: (window.event as MouseEvent).pageY + "px",
      left: (window.event as MouseEvent).pageX + "px"
    }); // In the right position (the mouse)
		
		$('.custom-menu li').hide();
		$('.custom-menu li').filter('[data-label=' + grouptype + ']').show();
	
		if(id2 != ''){
			select_id2 = id2.split("-")[0];
		}
		
		var first_id = g_id.split("-");
		
	  select_id = first_id[0];
	}

 const gotoextern = function(target:string){
		switch (target) {
			case "ncbi_gene":
				window.open("https://www.ncbi.nlm.nih.gov/gene?cmd=search&term=" + select_id, "_blank");
				break;
			case "geo":
				window.open("https://www.ncbi.nlm.nih.gov/geoprofiles?term=" + select_id, "_blank");
				break;
			case "kegg":
				window.open("https://www.genome.jp/dbget-bin/www_bfind_sub?dbkey=genes&keywords=" + select_id + "&mode=bfind&max_hit=100", "_blank");
				break;
			case "ncbi_protein":
				window.open("https://www.ncbi.nlm.nih.gov/protein/" + select_id, "_blank");
				break;
			case "uniprot":
				window.open("https://www.uniprot.org/uniprot/?query=" + select_id, "_blank");
				break;
			case "tbrnat":
				window.open("https://bioinformatics.niaid.nih.gov/tbrnat/?term=" + select_id2, "_blank");
				break;
      default:
        break;
		}
	}

  const handleClickOutside = () => {
    $(".custom-menu li").hide();
    $(".custom-menu").hide();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    if (props.plinkResults.length > 0) {
      const s = props.plinkResults.filter((r) => r.id == props.selectedrowid).map((r) => r.id);
      setRowSelectionModel(s);
      if(apiRef.current?.getSortedRowIds() != null){
        var selectedpage = calculateDataGridPage(apiRef.current?.getSortedRowIds(), props.selectedrowid, paginationModel.pageSize);
        apiRef.current.setPage(selectedpage);
      }
    };

    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          const resizeObserverErrDiv = document.getElementById(
              'webpack-dev-server-client-overlay-div'
          );
          const resizeObserverErr = document.getElementById(
              'webpack-dev-server-client-overlay'
          );
          if (resizeObserverErr) {
              resizeObserverErr.setAttribute('style', 'display: none');
          }
          if (resizeObserverErrDiv) {
              resizeObserverErrDiv.setAttribute('style', 'display: none');
          }
      }
    });

    return function() {
      document.addEventListener('click', handleClickOutside, true);
    }
  }, [props.selectedrowid]);
 
  
  const handleGoToPage = () => {
    if (apiRef.current !== null) {
      apiRef.current.setPage(10);
    }
  }

    return (
      <div>
        {/* <Button onClick={handleGoToPage}>Go to page 1</Button> */}
        {/* <div className='row'>
          <label className='right-float'>Total: {props.plinkResults.length}</label>
        </div> */}
        <div>
          <DataGrid 
            rows={props.plinkResults} 
            columns={GWASResultColumns}  
            apiRef={apiRef}

            getRowHeight={() => 'auto'}
            getEstimatedRowHeight={() => 200}

            initialState={{
                sorting: {
                  sortModel: [{ field: 'P', sort: 'asc' }],
                },
              }}

            slotProps={{
                pagination: {
                  showFirstButton: true,
                  showLastButton: true,
                },
            }}

            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 25, 50, 100]}

            onRowSelectionModelChange={(e) => {
              setRowSelectionModel(e);
              const selectedIDs = new Set(e);
              const selectedRows = props.plinkResults.filter((r) => selectedIDs.has(r.id));
              setSelectedRows(selectedRows);
            }}
            rowSelectionModel={rowSelectionModel}

            sx={{ ".MuiDataGrid-cell": {p: 1} }}
          />
        </div>
        <div>
          <ul className='custom-menu'>
              <li data-label="Gene"><a onClick={() => gotoextern('ncbi_gene')} >NCBI Gene Database</a></li>
              <li data-label="Gene"><a onClick={() => gotoextern('geo')} >NCBI Gene Expression Omnibus</a></li>
              <li data-label="Gene"><a onClick={() => gotoextern('kegg')} >KEGG Genes Database</a></li>
              <li data-label="Gene"><a onClick={() => gotoextern('tbrnat')} >TBRNAT</a></li>
              <li data-label="Protein"><a onClick={() => gotoextern('ncbi_protein')} >NCBI Protein</a></li>
              <li data-label="Protein"><a onClick={() => gotoextern('uniprot')} >UniProt</a></li>
          </ul>
        </div>
      </div>
    );
};