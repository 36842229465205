import * as React from "react";
import { UISref, Transition, isNullOrUndefined } from "@uirouter/react";
import {removeOrcidCode} from './utils/gapCommon';
import { RELEASE_VER} from '../App.constants';

export default class ReleaseNotes extends React.Component<Transition> {
 
  render() {
    removeOrcidCode("releasenotes", this.props);
    return (
      <div className="gap-page-content">
        <div className="container">
          <h3>Release Notes</h3>
          <hr />
          <p><b>Version {RELEASE_VER}</b>: (March 2025):</p>
          <ul>
            <li>Updated TB Profiler to version 6.6.3</li>
            <li>Updated NTM Profiler to version 0.6.0</li>
          </ul>
          <p><b>Version 1.21</b>: (September 2024):</p>
          <ul>
            <li><b>Updated TB Portals Genome Collection VCF:</b> file with 9035 TB genomes.</li>
            <li>Enhancements to optimize performance when using large VCF files.</li>
          </ul>
          <p><b>Version 1.20</b>: (July 2024):</p>
          <ul>
            <li>Allow users to enter multiple SRA or ENA IDs to run TB Profiler or NTM profiler without uploading sequence files.</li>
            <li>The TB Profiler's version has been updated to 6.2.1</li>
          </ul>
          <p><b>Version 1.19</b>: (April 2024):</p>
          <ul>
            <li>Allow users to enter a DRR ID to run TB Profiler and NTM profiler without uploading sequence files.</li>
          </ul>
          <p><b>Version 1.18</b>: (April 2024):</p>
          <ul>
            <li>Allow users to enter SRA ID to submit TB Profiler and NTM profiler tool without uploading sequence files.</li>
          </ul>
          <p><b>Version 1.17</b>: (March 2024):</p>
          <ul>
            <li>Added NTM Resistance Prediction (NTM-Profiler v0.4.0)</li>
          </ul>
          <p><b>Version 1.16</b>: (January 2024):</p>
          <ul>
            <li>The TB Profiler's version has been updated to v.5.0.1.</li>
          </ul>
          <p><b>Version 1.15</b>: (September 2023):</p>
          <ul>
            <li>Enhanced List management.</li>
            <li>Enable "all samples" distance network clustering.</li>
            <li>Replace "Import Cohorts from DEPOT" with "Sample List" in GWAS, Clustering, Distance, Cohort Visualization.</li>
            <li>Add "no data" type in Clustering.</li>
            <li>Fix UniPrort URL issues in Variant Search, Distance,  Cohort Visualization.</li>
          </ul>
          <p><b>Version 1.14</b>: (March 2023):</p>
          <ul>
            <li>Added sample clustering using Plink PCA with 2D and 3D visualization.</li>
            <li>Added save sample list as DEPOT cohort in List Manager.</li>
          </ul>
          <p><b>Version 1.13</b>: (October 2022):</p>
          <ul>
            <li>Replaced AngularJS 1.7 to React version 17.</li>
          </ul>
          <p><b>Version 1.12.1</b>: (May 2022):</p>
          <ul>
            <li>Improved Variant Search performance.</li>
            <li>Label changed from "RefseqID" to "Gene ID" on Variant Search.</li>
            <li>On Variant Search, a bug that caused certain vcf files does not function has been fixed.</li>
          </ul>

          <p><b>Version 1.12</b>: (February 2022):</p>
          <ul>
            <li>Enabled the GAP Admin to upload NTM or any other reference genomes.</li>
            <li>Added the NCBI genome browser in Variant Search.</li>
            <li>"User Group" is now available in List Management.</li>
            <li>"VCF file owner" is now available for each VCF file.</li>
          </ul>
          <p><b>Version 1.11</b>: (November 2021):</p>
          <ul>
            <li>"Log In with OCRiD" is available.</li>
          </ul>

          <p><b>Version 1.10</b>: (October 2021):</p>
          <ul>
            <li><span style={{ color: 'red' }}>New</span> <a href="/tbportalsdata" target="_blank">Resistant Prediction and Lineage information</a> of the NIAID TB Portals genomic data using the TBProfiler.</li>
            <li>TBProfiler version updated to 3.0.8</li>
          </ul>

          <p><b>Version 1.9</b>: (July 2021):</p>
          <ul>
            <li>Optimized large TB Portal genomic sample file (converting from a vcf to a pgen format) to improve the performance of GWAS or Distance Network tools. </li>
            <li>Upgraded NCBI Genomics browse to the latest version</li>
          </ul>

          <p><b>Version 1.8</b>: (May 2021) Added functionality:</p>
          <ul>
            <li>Sharing VCF files between members of a user group: users can share a VCF file within a user group. To create a user group, please contact TBPortalsSupport@nih.gov. </li>
            <li>List Management: users can upload lists of genes and use the lists in GWAS and cohort visualization.</li>
            <li>TBProfiler version updated to 3.0.4</li>
          </ul>

          <p><b>Version 1.7</b>: (February 2021):</p>
          <ul>
            <li><b>List Management:</b> users can upload lists of samples and use the lists in GWAS and cohort visualization. </li>
            <li><b>Updated TB Portals Genome Collection VCF:</b> file with 2661 samples.</li>
            <li>Minor bug fixes.</li>
          </ul>

          <p><b>Version 1.6.1</b>: (November 2020):</p>
          <ul>
            <li>Angular and JQuery version update</li>
            <li>Minor changes to Cohort Visualization UI</li>
            <li>Optimized VCF file loading on pages to reduce load time.</li>
          </ul>


          <p><b>Version 1.6</b>: (October 2020) Added functionality:</p>
          <ul>
            <li><b>Distance Network Optimization:</b> optimized the computation of distance networks to allow for the analysis of large cohorts. </li>
            <li><b>Genomic Cohort Visualization:</b> added bar chart showing the number of each type of variant.</li>
            <li><b>Dynamic Filters:</b> added the ability to filter variants by impact type.  Impact filters are created dynamically from the annotation values in the VCF.</li>
          </ul>

          <p><b>Version 1.5</b>: (August 2020) Added functionality:</p>
          <ul>
            <li><b>Resistance Prediction:</b> Upgraded TB Profiler to version 2.8.12. </li>
            <li><b>VCF File Upload:</b> Allow upload of VCF files annotated using SNPEff in ANN format.</li>
            <li><b>GWAS GEMMA:</b> genome wide analysis can now be performed using the GEMMA algorithm.</li>
            <li><b>Genomic Cohort Visualization:</b> identify variants found repeatedly within a cohort. Graphical reporting of these variants will be added in future releases.</li>
            <li><b>Added link to the TBRNAT application.</b></li>
            <li><b>Added the ability to link to the GAP application from external programs.</b></li>
            <li><b>Added links to other TB Portals sites.</b></li>
            <li><b>Added a new system VCF file with 2225 TB genomes.</b></li>
          </ul>

          <p><b>Version 1.4</b>: (June 2020) Changed:</p>
          <ul>
            <li><b>Containerized and hosting on AWS Cloud infrastructure.</b></li>
          </ul>
          <p><b>Version 1.3</b>: (February 2020) Added functionality:</p>
          <ul>
            <li><b>Distance Network Cohort Distance:</b> a distance network can be generated from a cohort imported from DEPOT. </li>
            <li><b>Distance Network visualization enhancements:</b> the user interface for the distance network has been enhanced to provide advanced layout controls.</li>
            <li><b>Distance Network Edge Distance Filtering:</b> network edges can now be filtered out using a distance threshold.</li>
            <li><b>Resistance Prediction:</b> TBprofiler algorithm is now updated to the latest version.</li>
            <li><b>GWAS GLM:</b> genome wide analysis can now be conducted using the Plink 2.0 implementation of the general linear model. </li>
          </ul>

          <p><b>Version 1.2</b>: (May 2019) Added functionality:</p>
          <ul>
            <li><b>Create cohorts for use in DEPOT:</b> added the ability to create cohorts in the variant search feature. The created cohorts are written to DEPOT for use in downstream analysis.</li>
            <li><b>Metadata upload:</b> metadata can be uploaded for samples. This metadata will be used throughout the application to provide more information about samples.</li>
            <li><b>Distance Network Color by metadata:</b> metadata can be used to color the distance network to provide more information about the samples.</li>
            <li><b>Distance Network visualization enhancements:</b> the user interface for the distance network has been enhanced to provide controls for manipulating the graph. </li>

          </ul>

          <p><b>Version 1.1</b>: (December 2018) Added functionality:</p>
          <ul>
            <li><b>Import Cohorts from DEPOT:</b> added ability to import cohorts from the TB Portals DEPOT</li>
            <li><b>Variant Search:</b> allows the user to search for variants in a gene or genomic region of interest.</li>
            <li><b>Genomic Distance Calculation:</b> allow user to compute the genomic distance between samples and to view a network of closely related samples.</li>
            <li><b>Reference Genome Specification:</b>  the user can now specify the reference genome used to create a multi-sample vcf file.</li>
            <li><b>Genome Browser switch reference:</b> the genome browser now allows the user to switch the reference genome.</li>
          </ul>
          <p><b>Version 1.0</b> : Initial release with TB GWAS, Drug Resistance Prediction, and TB Genome Browser - January 2017</p>


        </div>
      </div>
    );
  }
}
