import React, { ChangeEvent } from 'react';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import * as BSIcon from "react-icons/bs";
import NCBIReference from '../../../../domain/NCBIReference';

interface ReferenceTableData {
  searchResults: GridRowsProp;
  onViewReference(row: NCBIReference) : void;
  onEditReference(row: NCBIReference) : void;
  updateReference(row: NCBIReference) : void;
  onDeleteReference(row: NCBIReference) : void;
}

export default function ReferenceTable_MUI (props : ReferenceTableData) {

  const ReferenceColumns : GridColDef[] = [
    { field: 'referenceName', headerName: 'Reference Name', sortable: true, width: 400},
    { field: 'NCBI_Embedded', headerName: 'NCBI Embedded Parameter', sortable: false, flex:1, width: 500},
    { field: 'isdefault', headerName: 'Default', sortable: false, width: 160,
      renderCell: (params) => {
        if(params.row.isdefault){
          return (
            <div className="checkbox disabled">
              <label>
                <input type="checkbox" checked={params.row.isdefault} />
              </label>
            </div>
          )
        }
        else{
          return (
            <div className="checkbox">
              <label>
                <input type="checkbox" checked={params.row.isdefault} onChange={event => onDefaultChange(event, params.row)} />
              </label>
            </div>
          )
        }
      }
    },
    {field: 'actions', type: 'actions', headerName: 'Actions', width: 200,
      renderCell: (params) => {
        return <div>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => props.onEditReference(params.row)}>
            <BSIcon.BsPencil className="margin-bottom-3" title="Edit Reference" />
          </button>
          <button type="button" className="btn btn-light btn-outline-secondary btn-sm margin-right-5" onClick={() => props.onViewReference(params.row)}>
            <BSIcon.BsViewStacked className="margin-bottom-3" title="View Reference" />
          </button>
          {(!params.row.isdefault) &&
            <button type="button" className="btn btn-danger btn-sm" onClick={() => onDeleteReference(params.row)}>
              <BSIcon.BsTrashFill className="margin-bottom-3" title="Delete Reference" />
            </button>
          }

        </div>;
      }
    }
  ];

  const onDeleteReference =(row: NCBIReference)=>{
    var r = window.confirm("Do you want to delete Reference " + row.referenceName);
		if (r === false) {
		    return;
		}

    props.onDeleteReference(row);
  }

  const onDefaultChange =  (event: ChangeEvent<HTMLInputElement>, row: NCBIReference) => {
    const { checked } = event.target;
    row.isdefault = event.target.checked;
    props.updateReference(row);
  }

  
  return (
    <div>
      <DataGrid
        rows={props.searchResults}
        columns={ReferenceColumns}
        getRowId={(row) => row.referenceId}

        getRowHeight={() => 'auto'}
        getEstimatedRowHeight={() => 200}

        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}

        slotProps={{
          pagination: {
            showFirstButton: true,
            showLastButton: true,
          },
        }}

        pageSizeOptions={[10, 25, 50, 100]}
        sx={{ ".MuiDataGrid-cell": { p: 1 } }}
      />
    </div>

  );
};