import * as React from "react";
import NcbiSViewer from '../../Genome/NcbiSViewer';
import NCBIReference from '../../../domain/NCBIReference'
import UserSession from '../../../services/UserSession'
import axios from "axios";
import VCF from '../../../domain/VCF'
import SampleList from "../../../domain/SampleList"
import Cohort from "../../../domain/Cohort"
import Plotly from './GWASPlotly';
import { CSVLink } from "react-csv";
import PlinkRowData from "../../../domain/PlinkRowData";
import BlockUi from '@availity/block-ui';
import { DNA } from  'react-loader-spinner';
import 'loaders.css/';
//import GWASTable from './GWASTable';
import {findAndRemove} from '../../utils/gapCommon'
import { UISref, UISrefActive, Transition} from "@uirouter/react";
import './GWAS.css';
import queryString from 'query-string';
import API_URL from '../../../services/Enviroment';
import {removeOrcidCode} from '../../utils/gapCommon';
import GWASTable_MUI from "./GWASTable_MUI";

type GWASState = {
    blocking: boolean,
    blockmessage: string,
    selectedncbivalue: string,
    displaySviewPosition : string,
    selectedvcffile : string,
    selected_controls : string,
    selected_cases : string,
    cohort_control: string,
    cohort_case: string,
    selectedResultRowid: string,
    ncbiName : string,
    assoc : string,
    pfilter: number,
    plinkrowdata: string,
    reloadSview: number;
    showPlotly : boolean,
    haserror: boolean,
    ssridtype: string,
    depotsupport: boolean,
    errors: string
}

type PlinkResult = {
    rowdata: PlinkRowData
}

const associations=["fisher", "fisher-midp", "GLM", "GEMMA"];

export default class GWAS extends React.Component <Transition, GWASState> {
    NCBIs: { [key: string]: NCBIReference } = {};
    vcffiles = Array<VCF>();
    sampleList_controls = Array<SampleList>();
    sampleList_cases = Array<SampleList>();
    userSampleList = Array<SampleList>();

    cohorts_controls = Array<Cohort>();
    cohorts_cases = Array<Cohort>();

    verified_listclass="col-md-8";
	verified_listclass1="col-md-6";
    usersession = UserSession.getUserSession();
    plinkResults = [];
    plinklog = "";
    downloaddata = Array<PlinkRowData>();
    
    constructor(props: Transition) {
        super(props);

        this.state = {
            blocking: false,
            blockmessage: "loading ...",
            selectedncbivalue: "",
            displaySviewPosition: "",
            selectedvcffile: "",
            selected_controls: "",
            selected_cases: "",
            cohort_control: "",
            cohort_case: "",
            selectedResultRowid: "",
            ncbiName: "",
            assoc: "fisher",
            pfilter: 0.05,
            plinkrowdata: "",
            showPlotly: false,
            haserror: false,
            reloadSview: 1,
            ssridtype:'u',
            depotsupport: false,
            errors: ""
        };
     }

    loaddata = () => {
        this.loadReferences();
    }

    loadReferences = () => {
        let url = API_URL +'admin/getAppReferences';
        axios.get(url)
            .then((response) => {
                this.NCBIs = response.data;
                this.loadvcffile();
            })
            .catch((error) => {
                console.error("There was an error!" + error.message);
            });
    } 

    loadvcffile = () => {
        let url = API_URL +'genomics/VCFfiles';
        axios.post(url, { type: 'vcf' })
            .then(response => {
                this.vcffiles = response.data;

                var defaultvcffile = this.vcffiles[0];
                for (var i = 0; i < this.vcffiles.length; i++) {
                    var vcf = this.vcffiles[i];
                    if (vcf.isdefault) {
                        defaultvcffile = vcf;
                        break;
                    }
                }

                var selectedvcf = JSON.parse(JSON.stringify(defaultvcffile));
                var ncbiref = this.NCBIs[selectedvcf.reference].NCBI_Embedded;
                var reloadSview = this.state.reloadSview +1;
                var stateJson = {
                    selectedvcffile: JSON.stringify(defaultvcffile),
                    selectedncbivalue: ncbiref,
                    ncbiName: selectedvcf.reference,
                    reloadSview: reloadSview,
                    depotsupport: defaultvcffile.hasmetadata
                }

                this.loadUserSampleList(JSON.stringify(stateJson));
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
            });
    }

    loadUserSampleList = (stateJsonStr : string) => {
        var stateJson = JSON.parse(stateJsonStr) as GWASState;
        let url = API_URL +'listmanagement/getList';
        let selectedvcfid = (JSON.parse(stateJson.selectedvcffile) as VCF).vcfid;
        axios.get(url, {params: {vcfid: selectedvcfid}})
            .then(response => {
                var userlist = response.data;
        		this.userSampleList = userlist.filter(function(list : SampleList){
        			return (list.listtype == 'Sample');
        		});
        		
        		this.sampleList_controls = this.userSampleList;
        		this.sampleList_cases = this.userSampleList;
                
                stateJson['selected_controls'] = '';
                stateJson['selected_cases'] = '';
                this.setState (stateJson);
            })
            .catch(error => {
                console.error('There was an error!' + error.message);
                this.setState ({errors: 'There was an error!' + error.message});
            });
	}

    onEnterAssoc = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;
        this.setState ({assoc: target.value});
    }

    onEnterPfilter = (event: React.FormEvent <HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        // let { value, min, max } = event.target;
        // value = Math.max(Number(min), Math.min(Number(max), Number(value)));


        this.setState ({pfilter: parseFloat(target.value)});
    }

    onChangevcf = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;
        var selectedvcf = JSON.parse (target.value);
        var ncbiref = this.NCBIs[selectedvcf.reference].NCBI_Embedded;

        var reloadSview = this.state.reloadSview + 1;
        var stateJson = {
                selectedvcffile: target.value, 
                selectedncbivalue: ncbiref, 
                ncbiName: selectedvcf.reference,
                reloadSview: reloadSview
            } as GWASState;
        if(this.usersession.isverified){
            stateJson.depotsupport = selectedvcf.hasmetadata;
			if(!selectedvcf.hasmetadata){
				stateJson.ssridtype="u";
			}
		}

		this.loadUserSampleList(JSON.stringify(stateJson));
	}

    onSsridtypeChange = (event: React.FormEvent<HTMLInputElement>) => {
        var target = event.target as HTMLInputElement;
        this.setState({ssridtype:target.value});
    }

    SearchData = () => {
        this.setState({
            blocking: true,
            showPlotly: false,
            selectedResultRowid: ""
        });
        
        if(this.state.ssridtype == 'u'){
            var controlsdata = (JSON.parse(this.state.selected_controls) as SampleList).totallistcontent;
			var casesdata =  (JSON.parse(this.state.selected_cases) as SampleList).totallistcontent;
		
			this.getPlinkResults(controlsdata, casesdata);
        }
        else{
            var controlsdata = (JSON.parse(this.state.cohort_control) as Cohort).ssrids;
            var casesdata = (JSON.parse(this.state.cohort_case) as Cohort).ssrids;
            this.getPlinkResults(controlsdata, casesdata);
        }
	}

    getPlinkResults=(controlsdata : string, casesdata: string) => {
        let selectedvcf  = JSON.parse(this.state.selectedvcffile) as VCF;
		var vcffilename = selectedvcf.filename;
		
		if(selectedvcf.owner!=null && selectedvcf.owner !="system"){
			vcffilename=selectedvcf.owner + "/" + vcffilename;
		}
	
        let url = API_URL +'genomics/Plink';
        axios.post(url, 
            {
                "C1": controlsdata,
                "C2": casesdata, 
                "assoc": this.state.assoc,
                "vcffile": vcffilename,
                "pfilter": this.state.pfilter
            }
        )
        .then(response => {
            var plinkdata = response.data;
            var logstring = response.data.Log;				
            var searchStr= "Among remaining phenotypes";
            var startpos = logstring.indexOf(searchStr);
            if(startpos == -1){
                this.plinklog = "Note: No phenotypes present.";
            }
            else{
                var endpos = logstring.indexOf('.', startpos) + 1;
                this.plinklog = logstring.substring(startpos, endpos);
            }
            
            plinkdata = this.PrepareData(plinkdata.Results);	            
            this.plinkResults = plinkdata;
            this.setupPlinkResultTable();
            this.setState({
                blocking: false,
                showPlotly: true,
                plinkrowdata: JSON.stringify(plinkdata)
            });
        })
        .catch(error => {
            console.error('There was an error!' + error.message);
            this.setState({blocking: false});
           
        });

	}

    setupPlinkResultTable = () => {
		this.downloaddata=this.addnewcolumn(this.plinkResults);
	}

    addnewcolumn = (resultData : Array<PlinkRowData>) => {
		for (var k = 0; k < resultData.length; k++) {
			var data = resultData[k];
            if(this.state.assoc == "GLM"){
                data['Count'] = data['A1_CASE_CT'] + "\\" + data['A1_CTRL_CT'];
           }
           else{
            data['Count'] = data['C_A'] + "\\" + data['C_U'];
           }

           data['selected'] = false;
		}
		
		return resultData;
	}

    PrepareData=(data :  Array<PlinkResult>) => {
        console.log(data);
		var newdata = [];
		for (var i = 0; i < data.length; i++) {
			var olddata = (data[i] as PlinkResult).rowdata;
			olddata.BP=parseFloat(olddata.BP.toString());
			
			if(olddata.hasOwnProperty("P")){
				olddata.P=parseFloat(olddata.P.toString());
			}
			
			if(olddata.hasOwnProperty("A1") && olddata.A1.length > 10){
				olddata.A1 = olddata.A1.substring(0,10) + "....";
			}
			
			if(olddata.hasOwnProperty("A2") && olddata.A2.length > 10){
    		    olddata.A2 = olddata.A2.substring(0,10) + "....";
			}
			newdata.push(olddata);
		};
     		
		return newdata;
	};

    changeSampleListcontrols = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;
        var selectedValue = target.value;
        var sampleList_new_cases = JSON.parse(JSON.stringify(this.userSampleList));
        if(selectedValue !== ''){
		    findAndRemove(sampleList_new_cases, 'listname', (JSON.parse(selectedValue) as SampleList).listname);
        }
        this.sampleList_cases = sampleList_new_cases;
        this.setState ({selected_controls: selectedValue});
	}
	
	changeSampleListcases = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;
        var selectedValue = target.value;
        var sampleList_new_control = JSON.parse(JSON.stringify(this.userSampleList));
        if(selectedValue !== ''){
		    findAndRemove(sampleList_new_control, 'listname', (JSON.parse(selectedValue) as SampleList).listname);
        }
        this.sampleList_controls = sampleList_new_control;
        this.setState ({selected_cases: selectedValue});
    }

    changeCohortcontrols = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;
        var selectedValue = target.value;
        var cohorts_new_cases = JSON.parse(JSON.stringify(this.usersession.Depot_cohorts));
        if(selectedValue !== ''){
		    findAndRemove(cohorts_new_cases, 'name', (JSON.parse(selectedValue) as Cohort).name);
        }
        this.cohorts_cases = cohorts_new_cases;
        this.setState ({cohort_control: selectedValue});
	}

    changeCohortcases = (event: React.FormEvent<HTMLSelectElement>) => {
        var target = event.target as HTMLSelectElement;
        var selectedValue = target.value;
        var cohots_new_control = JSON.parse(JSON.stringify(this.usersession.Depot_cohorts));
        if(selectedValue !== ''){
		    findAndRemove(cohots_new_control, 'name', (JSON.parse(selectedValue) as Cohort).name);
        }
        this.cohorts_controls  = cohots_new_control;
        this.setState ({cohort_case: selectedValue});
    }

    onReloadCohorts =() => {   //will add a call to get latest cohorts from DEPOT.
		if(this.usersession.isverified){
            this.setState({blocking: true, blockmessage:"Refresh cohorts..."}); 
            let url = API_URL +'genomics/reloadDepotData';
            axios.post(url, {email:this.usersession.email})
                .then(response => {
                    this.setState({blocking: false}); 
                    this.usersession.Depot_cohorts = response.data;
	        	    sessionStorage.setItem("userSession", JSON.stringify(this.usersession));
                    this.cohorts_cases = this.usersession.Depot_cohorts;
                    this.cohorts_controls = this.usersession.Depot_cohorts;
                })
                .catch(error => {
                    console.error('There was an error!' + error.message);
                    this.setState ({errors: 'There was an error!' + error.message, blocking: false});
                });
		}
	}

    openGen =(row: PlinkRowData) => {	
        console.log(row.BP);
        var reloadSview = this.state.reloadSview +1;
        this.setState ({displaySviewPosition: row.BP.toString(), reloadSview: reloadSview, selectedResultRowid: row.id});
	}

    updateResultTable =(id: string) =>{
        console.log(id);

        var bp = "";
        for (var i = 0; i < this.plinkResults.length; i++) {
            var plinkrow = this.plinkResults[i] as PlinkRowData;
            if (plinkrow.id === id) {
                bp = plinkrow.BP.toString();
                break;
             }
        }
        var reloadSview = this.state.reloadSview +1;
        this.setState({selectedResultRowid: id, reloadSview: reloadSview, displaySviewPosition: bp});
    }

    componentDidMount(){ 
        this.loaddata();
    }

    updateScreen =() =>{
        removeOrcidCode("GWAS", this.props);
		var newsession = JSON.parse(sessionStorage.userSession);
		if(this.usersession.email != "anonymous" && newsession.email === "anonymous" ) { //logout
			this.usersession = newsession;
			this.loaddata();
		}
		else{
			this.usersession = newsession;
			if(this.usersession.isverified){
                this.verified_listclass="col-md-6";
                this.verified_listclass1="col-md-10";
            }
            else{
                this.verified_listclass="col-md-8";
                this.verified_listclass1="col-md-6";
            }
            this.cohorts_cases = this.usersession.Depot_cohorts;
            this.cohorts_controls = this.usersession.Depot_cohorts;
		}
    }

    render(){
        this.updateScreen();
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking} loader={<DNA visible={true} height="80" width="80" ariaLabel="DNA-loading" wrapperStyle={{}} wrapperClass="DNA-wrapper"/>}>
                    <div className="gap-page-content">
                        <div className="container">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className='breadcrumb-item'>
                                        <UISref to="home"><a>Home</a></UISref>
                                    </li>
                                    <li className='breadcrumb-item'>
                                        <UISref to="variants"><a>Variant</a></UISref>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">GWAS</li>
                                </ol>
                            </nav>

                            <h1>Genome Wide Association Study</h1>
                            <p>
                                Identify statistically significant SNPs which differentiate the cohorts. This analysis uses the association methods implemented in the <a href="https://www.cog-genomics.org/plink2" rel="noreferrer" target='_blank'>PLINK</a> software package.
                            </p>
                            <hr />
                            <form name="vcfForm">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label htmlFor="vcffile">VCF File</label>
                                        <div className="form-group">
                                            <select
                                                className="form-select"
                                                value={this.state.selectedvcffile}
                                                onChange={this.onChangevcf}>
                                                {
                                                    Object.entries(this.vcffiles).map(([key, value], i) => (
                                                        <option key={key} value={JSON.stringify(value)}>{value.displayname}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="ncbiName">Reference genome</label>
                                        <input id="ncbiName" type="text" value={this.state.ncbiName} disabled={true} className="form-control" style={{ width: "200px" }} />
                                    </div>
                                </div>
                            </form>
                            <br></br>
                            <form name="cohortForm" className="form-inline">
                                <div className="row" >
                                    <div className="col-md-6">
                                        <span> <label className="group_title_font">Sample List&nbsp;&nbsp;</label></span>
                                        
                                        {this.usersession.isverified &&
                                            <UISref to="listmanagement"><a>New Sample List</a></UISref>
                                        }
                                    </div>
                                </div>
                                
                                <fieldset className="custom_fieldset col-md-10" >
                                    <div className="row ">
                                        <div className="col-md-6">
                                            <div className="form-group margin-top-10">
                                                <label htmlFor="gcontrols">Cohort1 (Controls)</label> 

                                                <select
                                                    id="gcontrols"
                                                    style={{ width: '500px' }}
                                                    className="form-select"
                                                    value={this.state.selected_controls}
                                                    onChange={this.changeSampleListcontrols}>
                                                    <option value="">-- please choose a sample list --</option>
                                                    {
                                                        Object.entries(this.sampleList_controls).map(([key, value], i) => (
                                                            <option key={key} value={JSON.stringify(value)}>{value.listname}</option>
                                                        ))
                                                    }
                                                </select>

                                                <p className="help-block">File containing the ids for the controls</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group margin-top-10">
                                                <label htmlFor="gcases">Cohort2 (Cases)</label> 
                                                <select
                                                    id="gcases"
                                                    className="form-select"
                                                    style={{ width: '500px' }}
                                                    value={this.state.selected_cases}
                                                    onChange={this.changeSampleListcases}>
                                                    <option value="">-- please choose a sample list --</option>
                                                    {
                                                        Object.entries(this.sampleList_cases).map(([key, value], i) => (
                                                            <option key={key} value={JSON.stringify(value)}>{value.listname}</option>
                                                        ))
                                                    }
                                                </select>

                                                <p className="help-block">File containing the ids for the cases</p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <div className="row">
                                <span> <label className="group_title_font">Analysis Type</label></span>
                            </div>
                            <form name="typeForm">
                                <fieldset className="custom_fieldset col-md-6" >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group margin-top-10">
                                                <label htmlFor="associations">Association</label>
                                                <select
                                                    className="form-select"
                                                    onChange={this.onEnterAssoc}
                                                    value={this.state.assoc}>
                                                    {
                                                        Object.entries(associations).map((association) => (
                                                            <option key={association[0]} value={association[1]}>{association[1]}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group margin-top-10">
                                                <label htmlFor="pfilter">pfilter (0-1)</label>

                                                <input type="number" className="form-control" id="pfilter" name="input"
                                                    placeholder="input a number (0-1)" value={this.state.pfilter} min="0"
                                                    max="1" width="60px" step="0.01" required
                                                    onChange={this.onEnterPfilter} />
                                                {/* {<i ng-show="typeForm.input.$error.required">*required</i>}
                                            {<i ng-show="!typeForm.input.$valid">*Not valid number!</i>} */}
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                            </form>
                            <br></br>
                            <button id="searchdata" type="button" className="btn btn-primary"
                                disabled={!this.state.selectedvcffile || !this.state.assoc || !this.state.pfilter ||
                                    (this.state.ssridtype == 'u' && (!this.state.selected_cases || !this.state.selected_controls)) ||
                                    (this.state.ssridtype == 's' && (!this.state.cohort_control || !this.state.cohort_case))
                                }
                                onClick={this.SearchData}>Submit
                            </button>

                            <div className="row">
                                <span className="redcolor">{this.state.errors}</span>
                            </div>

                            {this.state.showPlotly &&
                                <div>
                                    <Plotly plinkResults={this.state.plinkrowdata} updateResultTable={(id: string) => this.updateResultTable(id)} />
                                    <div>
                                        <div className="padding-bottom-10">
                                            {this.state.assoc != 'GEMMA' &&
                                                <div>
                                                    <CSVLink data={this.downloaddata} className="btn btn-success"
                                                        filename={"GWAS.csv"}
                                                        headers={[
                                                            { label: "P-Value", key: "P" },
                                                            { label: "Position", key: "BP" },
                                                            { label: "Impact", key: "Impact" },
                                                            { label: "AA Change", key: "AAChange" },
                                                            { label: "Ref Allele", key: "A2" },
                                                            { label: "Alt Allele", key: "A1" },
                                                            { label: "Gene ID", key: "GeneID" },
                                                            { label: "Gene Name", key: "GeneName" },
                                                            { label: "Protein ID", key: "ProteinID" },
                                                            { label: "Protein Name", key: "ProteinName" },
                                                            { label: "Count Cases/Controls", key: "Count" }
                                                        ]}>
                                                        <span className="glyphicon glyphicon-save"></span>Download
                                                    </CSVLink>
                                                </div>
                                            }
                                            {this.state.assoc == 'GEMMA' &&
                                                <div>
                                                    <CSVLink data={this.downloaddata} className="btn btn-success"
                                                        filename={"GWAS.csv"}
                                                        headers={[
                                                            { label: "P-Value", key: "P" },
                                                            { label: "Position", key: "BP" },
                                                            { label: "Impact", key: "Impact" },
                                                            { label: "AA Change", key: "AAChange" },
                                                            { label: "Ref Allele", key: "A2" },
                                                            { label: "Alt Allele", key: "A1" },
                                                            { label: "Gene ID", key: "GeneID" },
                                                            { label: "Gene Name", key: "GeneName" },
                                                            { label: "Protein ID", key: "ProteinID" },
                                                            { label: "Protein Name", key: "ProteinName" }
                                                        ]}>
                                                        <span className="glyphicon glyphicon-save"></span>Download
                                                    </CSVLink>
                                                </div>
                                            }
                                            <span className="">{this.plinklog}</span>
                                            <label className='right-float'>Total: {this.plinkResults.length}</label>
                                           
                                        </div>
                                        <div>
                                            <GWASTable_MUI 
                                                plinkResults={this.plinkResults} 
                                                assoc={this.state.assoc} 
                                                selectedrowid={this.state.selectedResultRowid} 
                                                openGen={(row: PlinkRowData) => this.openGen(row)} />

                                            {/* <GWASTable          
                                                plinkResults={this.plinkResults} 
                                                assoc={this.state.assoc} 
                                                selectedrowid={this.state.selectedResultRowid} 
                                                openGen={(row: PlinkRowData) => this.openGen(row)} /> */}
                                        </div>
                                    </div>
                                </div>
                            }
                            <br></br>
                            <NcbiSViewer NCBIEmbeddedParameter={this.state.selectedncbivalue} trackurl='' reloadSview={this.state.reloadSview} position={this.state.displaySviewPosition} />
                        </div>
                    </div>
                </BlockUi>
            </div>
        );
    };
}


